import { useCallback } from "react";
import {
  SALES_CREDIT_MEMO,
  SALES_INVOICE,
} from "../../routes/__authenticated/invoices";
import { apiHooks } from "../apis/norskGassnettApiHooks";

type UseGetInvoicePdfType = (invoice: {
  invoiceType: typeof SALES_INVOICE | typeof SALES_CREDIT_MEMO;
  id: string;
}) => Promise<string | null>;

export const useGetInvoicePdf = () => {
  const salesInvoice = apiHooks.useMutation("get", "/v1/invoice/{id}");
  const salesCreditMemo = apiHooks.useMutation(
    "get",
    "/v1/invoice/credit-memo/{id}",
  );

  const mutateAsync = useCallback<UseGetInvoicePdfType>(
    async (invoice) => {
      const isSalesCreditMemo = invoice.invoiceType === SALES_CREDIT_MEMO;

      if (isSalesCreditMemo) {
        const res = await salesCreditMemo.mutateAsync({
          params: {
            path: { id: invoice.id },
            query: { includePdf: true },
          },
        });

        return res.data?.salesCreditMemoPdf ?? null;
      } else {
        const res = await salesInvoice.mutateAsync({
          params: {
            path: { id: invoice.id },
            query: { includePdf: true },
          },
        });

        return res.data?.salesInvoicePdf ?? null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [salesInvoice.mutateAsync, salesCreditMemo.mutateAsync],
  );

  return {
    mutateAsync,
    isPending: salesInvoice.isPending || salesCreditMemo.isPending,
    error: salesInvoice.error || salesCreditMemo.error,
  };
};
