import { createBrowserRouter } from "react-router-dom";
// Views import
import Login, { PATH_SEGMENT as PATH_LOGIN } from "./routes/__anonymous/login";
import Root, { PATH as PATH_ROOT } from "./routes/root";
import ErrorPage from "./routes/error-page";
import Authenticated from "./routes/__authenticated";
import Index from "./routes/__authenticated/index";
import Usage, {
  PATH_SEGMENT as PATH_USAGE,
} from "./routes/__authenticated/usage";
import MeterReading, {
  PATH_SEGMENT as PATH_METER_READING,
} from "./routes/__authenticated/meter-reading";
import Invoices, {
  PATH_SEGMENT as PATH_INVOICES,
} from "./routes/__authenticated/invoices";
import InvoiceDetails, {
  PATH_SEGMENT as PATH_INVOICES_DETAILS,
} from "./routes/__authenticated/invoices.$invoiceType.$invoiceId";
import Info, { PATH_SEGMENT as PATH_INFO } from "./routes/__authenticated/info";
import Relocate, {
  PATH_SEGMENT as PATH_RELOCATE,
} from "./routes/__authenticated/relocate";
import ServiceReports, {
  PATH_SEGMENT as PATH_SERVICE_REPORTS,
} from "./routes/__authenticated/service-reports";
import Anonymous from "./routes/__anonymous";

export const router = createBrowserRouter([
  {
    path: PATH_ROOT,
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <Anonymous />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: PATH_LOGIN,
            element: <Login />,
            errorElement: <ErrorPage />,
          },
        ],
      },
      {
        element: <Authenticated />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Index />,
            errorElement: <ErrorPage />,
          },
          {
            path: PATH_USAGE,
            element: <Usage />,
            errorElement: <ErrorPage />,
          },
          {
            path: PATH_METER_READING,
            element: <MeterReading />,
            errorElement: <ErrorPage />,
          },
          {
            path: PATH_INVOICES,
            element: <Invoices />,
            errorElement: <ErrorPage />,
          },
          {
            path: PATH_INVOICES_DETAILS,
            element: <InvoiceDetails />,
            errorElement: <ErrorPage />,
          },
          {
            path: PATH_INFO,
            element: <Info />,
            errorElement: <ErrorPage />,
          },
          {
            path: PATH_RELOCATE,
            element: <Relocate />,
            errorElement: <ErrorPage />,
          },
          {
            path: PATH_SERVICE_REPORTS,
            element: <ServiceReports />,
            errorElement: <ErrorPage />,
          },
        ],
      },
    ],
  },
]);
