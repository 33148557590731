import {
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { matchIsValidTel, MuiTelInputInfo } from "mui-tel-input";
import {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  ReactNode,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useIdentifiedContext } from "../../contexts/IdentifiedContext";
import { apiHooks, Schemas } from "../../apis/norskGassnettApiHooks";
import LoadingButton from "../atomics/LoadingButton";
import MUIIcon from "../atomics/MUIIcon";
import MUITelInput from "../atomics/MUITelInput";
import StaticButton from "../atomics/StaticButton";

interface AddPhoneDialogWrapper {
  backFunction?: () => void;
  subtitle?: string;
  children: ReactNode;
}
const AddPhoneDialogWrapper: FC<AddPhoneDialogWrapper> = ({
  subtitle,
  backFunction,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open
      sx={{
        "& .MuiDialog-paper": {
          p: 2,
          pb: 3,
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <IconButton
          sx={{ visibility: backFunction ? "visible" : "hidden" }}
          onClick={backFunction}
        >
          <MUIIcon name="arrow_back" color="primary" />
        </IconButton>
        <DialogTitle variant="h4">{t("phone_dialog.title")}</DialogTitle>
        <IconButton sx={{ visibility: "hidden" }}>
          <MUIIcon name="arrow_back" />
        </IconButton>
      </Stack>
      <Stack alignItems="center" width="100%" height="100%" gap={2}>
        {subtitle && (
          <Typography variant="body1" maxWidth={400} textAlign="center">
            {subtitle}
          </Typography>
        )}
        {children}
      </Stack>
    </Dialog>
  );
};
const Stage = {
  Info: 0,
  SubmitPhone: 1,
  ConfirmCode: 2,
  Done: 3,
  Hidden: 4,
} as const;
const AddPhoneDialog = () => {
  const { t } = useTranslation();

  const { identity, refetchIdentity } = useIdentifiedContext();

  const startUpdateCustomerFlow = apiHooks.useMutation(
    "post",
    "/v1/customer/start-update-flow",
  );
  const completeUpdateCustomerFlow = apiHooks.useMutation(
    "post",
    "/v1/customer/complete-update-flow",
  );
  const [dialogStage, setDialogStage] = useState<
    (typeof Stage)[keyof typeof Stage]
  >(Stage.Info);

  const [phone, setPhone] = useState({
    value: "",
    info: null as MuiTelInputInfo | null,
  });
  const [confirmCode, setConfirmCode] = useState("");
  if (
    !identity ||
    (identity.formasjonPhoneNo && dialogStage === Stage.Info) // Comment this line to debug
  ) {
    return null;
  }

  if (dialogStage === Stage.Info) {
    return (
      <AddPhoneDialogWrapper subtitle={t("phone_dialog.info")}>
        <StaticButton onClick={() => setDialogStage(Stage.SubmitPhone)}>
          {t("phone_dialog.next")}
        </StaticButton>
      </AddPhoneDialogWrapper>
    );
  }

  if (dialogStage === Stage.SubmitPhone) {
    const canSubmitChange = matchIsValidTel(phone.value);
    const handlePhoneChange = (value: string, info: MuiTelInputInfo) => {
      setPhone({ value, info });
    };
    const handleSubmit: MouseEventHandler = async () => {
      if (!canSubmitChange || !phone.info?.numberValue) return;
      const body: Schemas["StartUpdateCustomerFlowRequestBody"] = {
        id: identity.id,
        phoneNumber: phone.info.numberValue,
      };
      await startUpdateCustomerFlow.mutateAsync({ body });
      setDialogStage(Stage.ConfirmCode);
    };
    return (
      <AddPhoneDialogWrapper subtitle={t("phone_dialog.input_phone")}>
        <MUITelInput
          label={t(`phone_dialog.phone`)}
          focused
          name="phone"
          value={phone.value}
          onChange={handlePhoneChange}
        />
        <LoadingButton
          onClick={handleSubmit}
          loading={startUpdateCustomerFlow.isPending}
          disabled={!canSubmitChange}
          endIcon={<MUIIcon name="arrow_forward" />}
        >
          {t("phone_dialog.submit_phone")}
        </LoadingButton>
      </AddPhoneDialogWrapper>
    );
  }

  if (dialogStage === Stage.ConfirmCode) {
    const handleCodeChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      const value = e.target.value;
      if (!/^\d{0,6}$/.test(value)) return;
      setConfirmCode(e.target.value);
    };

    const canSubmitCode = /^\d{6}$/.test(confirmCode);
    const handleSubmit = async () => {
      if (!canSubmitCode) return;
      const body: Schemas["CompleteUpdateCustomerFlowRequestBody"] = {
        id: identity.id,
        code: confirmCode,
        property: "number",
      };
      await completeUpdateCustomerFlow.mutateAsync({ body });
      await refetchIdentity();
      setDialogStage(Stage.Done);
    };

    return (
      <AddPhoneDialogWrapper
        subtitle={t("phone_dialog.input_code")}
        backFunction={() => setDialogStage(Stage.SubmitPhone)}
      >
        <TextField
          label={t("phone_dialog.code")}
          focused
          value={confirmCode}
          onChange={handleCodeChange}
        />
        <LoadingButton
          onClick={handleSubmit}
          disabled={!canSubmitCode}
          loading={completeUpdateCustomerFlow.isPending}
          endIcon={<MUIIcon name="arrow_forward" />}
        >
          {t("phone_dialog.submit_code")}
        </LoadingButton>
      </AddPhoneDialogWrapper>
    );
  }

  if (dialogStage === Stage.Done) {
    return (
      <AddPhoneDialogWrapper subtitle={t("phone_dialog.finished")}>
        <StaticButton onClick={() => setDialogStage(Stage.Hidden)}>
          {t("phone_dialog.close")}
        </StaticButton>
      </AddPhoneDialogWrapper>
    );
  }

  return null;
};

export default AddPhoneDialog;
