import React, { ChangeEvent, FC, useMemo, useState } from "react";
import {
  Box,
  Button,
  Grow,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { theme } from "../../styles/theme";
import { useGreeting } from "../../lib/hooks/useGreeting";
import { PATH as PATH_INVOICES } from "./invoices";
import { PATH as PATH_METER_READING } from "./meter-reading";
import { PATH as PATH_SERVICE_REPORTS } from "./service-reports";
import { PATH as PATH_RELOCATE } from "./relocate";
import SubpageWrapper from "../../lib/components/atomics/SubpageWrapper";
import MUIIcon from "../../lib/components/atomics/MUIIcon";
import { useIdentifiedContext } from "../../lib/contexts/IdentifiedContext";
import { apiHooks, Schemas } from "../../lib/apis/norskGassnettApiHooks";
import { getAddressStringForCustomer } from "../../lib/helpers/utils";
import dayjs from "dayjs";
import StaticButton from "../../lib/components/atomics/StaticButton";
import LoadingButton from "../../lib/components/atomics/LoadingButton";
import { EMAIL_REGEX } from "../../lib/helpers/regex";
import { matchIsValidTel, MuiTelInputInfo } from "mui-tel-input";
import MUITelInput from "../../lib/components/atomics/MUITelInput";
import { FCWithChildren } from "../../types";
import CollapsableAlert from "../../lib/components/complex/CollapsableAlert";
import { useErrorMessage } from "../../lib/hooks/useErrorMessage";

interface InfoBarProps {
  title: string;
  subtitle?: string;
  variant?: "info" | "warning" | "success" | "error";
  href: string;
}

const InfoBar: React.FC<InfoBarProps> = ({
  title,
  subtitle,
  variant = "info",
  href,
}) => {
  const border = theme.palette[
    variant === "info" || variant === "success" ? "secondary" : variant
  ].main;
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      <Button
        variant="outlined"
        sx={{
          height: 85,
          width: "100%",
          px: 4,
          position: "relative",
          borderRadius: 0,
          borderColor: border,
          borderWidth: "2px !important",
          bgcolor: "background.paper",
        }}
        href={href}
        endIcon={<MUIIcon name="arrow_forward_ios" />}
      >
        <Stack sx={{ width: "100%", height: "100%" }} justifyContent="center">
          <Typography variant="h5">{title}</Typography>
          {subtitle && <Typography variant="body1">{subtitle}</Typography>}
        </Stack>
      </Button>

      {/* Badge */}
      <Box
        sx={{
          position: "absolute",
          top: "52%",
          left: 1.1,
          transform: "translate(-50%, -50%)",
          lineHeight: 1,
        }}
      >
        <MUIIcon
          name={variant === "info"
            ? "info"
            : variant === "success"
            ? "check_circle"
            : "error"}
          color={variant === "info"
            ? "primary"
            : variant === "success"
            ? "secondary"
            : variant}
          fontSize="large"
          filled
        />
        <Box
          sx={{
            position: "absolute",
            top: "47%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: theme.palette.background.paper,
            width: "1.5em",
            height: "1.5em",
            borderRadius: "50%",
            zIndex: -1,
          }}
        />
      </Box>
    </Box>
  );
};

const Stage = {
  Viewing: 0,
  ChooseEdit: 1,
  EditEmail: 2,
  EditPhone: 3,
  ConfirmEmail: 4,
  ConfirmPhone: 5,
  FinishedEmailChange: 6,
  FinishedPhoneChange: 7,
} as const;

interface UserInfoEditingWrapperProps {
  title: string;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  backFunction?: () => void;
  gap?: number;
  noBackground?: boolean;
}
const UserInfoEditingWrapper: FCWithChildren<UserInfoEditingWrapperProps> = ({
  title,
  gap = 3,
  onSubmit,
  noBackground,
  backFunction,
  children,
}) => (
  <Grow in>
    <Paper
      sx={{
        p: 4,
        pt: 3,
        mb: 3,
        bgcolor: noBackground ? "transparent" : undefined,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <IconButton
          sx={{ visibility: backFunction ? "visible" : "hidden" }}
          onClick={backFunction}
        >
          <MUIIcon name="arrow_back" color="primary" />
        </IconButton>
        <Typography variant="h4" textAlign="center" width="100%">
          {title}
        </Typography>
        <IconButton sx={{ visibility: "hidden" }}>
          <MUIIcon name="arrow_back" />
        </IconButton>
      </Stack>
      <Stack
        component="form"
        onSubmit={onSubmit}
        direction="column"
        gap={gap}
        mt={2}
      >
        {children}
      </Stack>
    </Paper>
  </Grow>
);

const UserInfo: FC = () => {
  const { t } = useTranslation();
  const er = useErrorMessage();

  const { identity, refetchIdentity } = useIdentifiedContext();

  const startUpdateCustomerFlow = apiHooks.useMutation(
    "post",
    "/v1/customer/start-update-flow",
  );
  const completeUpdateCustomerFlow = apiHooks.useMutation(
    "post",
    "/v1/customer/complete-update-flow",
  );
  const [infoStage, setInfoStage] = useState<
    (typeof Stage)[keyof typeof Stage]
  >(Stage.Viewing);
  const [phone, setPhone] = useState({
    value: "",
    info: null as MuiTelInputInfo | null,
  });
  const [email, setEmail] = useState("");
  const [confirmCode, setConfirmCode] = useState("");

  if (infoStage === Stage.Viewing) {
    return (
      <Grow in key={0}>
        <Stack
          direction="column"
          alignItems="center"
          sx={{ mt: 3, width: "fit-content" }}
          gap={1}
        >
          <Stack direction="column" sx={{ width: "fit-content" }}>
            <Typography variant="h4" textAlign="center">
              {identity.name}
            </Typography>
            <Typography variant="body1" textAlign="center">
              {identity.email}
            </Typography>
            <Typography variant="body1" textAlign="center">
              {identity.formasjonPhoneNo}
            </Typography>
            <Typography variant="body1" textAlign="center">
              {getAddressStringForCustomer(identity)}
            </Typography>
          </Stack>
          <StaticButton
            transparent
            onClick={() => {
              setInfoStage(Stage.ChooseEdit);
            }}
            endIcon={<MUIIcon name="manage_accounts" filled />}
          >
            {t("home.edit_account.details")}
          </StaticButton>
        </Stack>
      </Grow>
    );
  }

  if (infoStage === Stage.ChooseEdit) {
    const handleEdit = (key: "formasjonPhoneNo" | "email") => {
      setPhone({
        value: identity.formasjonPhoneNo,
        info: null,
      });
      setEmail(identity.email);
      setInfoStage(
        key === "formasjonPhoneNo" ? Stage.EditPhone : Stage.EditEmail,
      );
    };
    return (
      <UserInfoEditingWrapper
        title={t("home.edit_account.title")}
        backFunction={() => setInfoStage(Stage.Viewing)}
        noBackground
        gap={1}
      >
        {(["formasjonPhoneNo", "email"] as const).map((key) => (
          <Stack
            key={key}
            gap={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>{identity[key]}</Typography>
            <Typography
              color="secondary"
              onClick={() => handleEdit(key)}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {t("home.edit_account.edit")}
            </Typography>
          </Stack>
        ))}
      </UserInfoEditingWrapper>
    );
  }

  if (infoStage === Stage.EditEmail || infoStage === Stage.EditPhone) {
    const changingEmail = infoStage === Stage.EditEmail;

    const canSubmitChange = identity &&
      (changingEmail
        ? identity.email !== email && EMAIL_REGEX.test(email)
        : identity.formasjonPhoneNo !== phone.value &&
          matchIsValidTel(phone.value));
    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    };
    const handlePhoneChange = (value: string, info: MuiTelInputInfo) => {
      setPhone({ value, info });
    };
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!changingEmail && !phone.info?.numberValue) return;
      const body: Schemas["StartUpdateCustomerFlowRequestBody"] = changingEmail
        ? {
          id: identity.id,
          email,
        }
        : {
          id: identity.id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          phoneNumber: phone.info!.numberValue!,
        };
      await startUpdateCustomerFlow.mutateAsync({ body });
      setInfoStage(changingEmail ? Stage.ConfirmEmail : Stage.ConfirmPhone);
    };
    return (
      <UserInfoEditingWrapper
        title={t(
          `home.edit_account.changing_${changingEmail ? "email" : "phone"}`,
        )}
        onSubmit={handleSubmit}
        backFunction={() => setInfoStage(Stage.ChooseEdit)}
      >
        {changingEmail
          ? (
            <TextField
              key={1}
              label={t(`home.edit_account.email`)}
              focused
              name="email"
              value={email}
              onChange={handleEmailChange}
            />
          )
          : (
            <MUITelInput
              key={2}
              label={t(`home.edit_account.phone`)}
              focused
              name="formasjonPhoneNo"
              value={phone.value}
              onChange={handlePhoneChange}
            />
          )}
        <CollapsableAlert
          alert={er(
            "update_customer",
            startUpdateCustomerFlow.error ?? undefined,
          )}
        />
        <LoadingButton
          type="submit"
          loading={startUpdateCustomerFlow.isPending}
          disabled={!canSubmitChange}
          endIcon={<MUIIcon name="arrow_forward" />}
        >
          {t("home.edit_account.submit_change")}
        </LoadingButton>
      </UserInfoEditingWrapper>
    );
  }

  if (infoStage === Stage.ConfirmEmail || infoStage === Stage.ConfirmPhone) {
    const confirmingEmail = infoStage === Stage.ConfirmEmail;
    const translationKey = confirmingEmail ? "email" : "phone";
    const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (!/^\d{0,6}$/.test(value)) return;
      setConfirmCode(e.target.value);
    };

    const canSubmitCode = /^\d{6}$/.test(confirmCode);
    const handleSubmitCode = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!canSubmitCode) return;
      const body: Schemas["CompleteUpdateCustomerFlowRequestBody"] = {
        id: identity.id,
        code: confirmCode,
        property: confirmingEmail ? "email" : "number",
      };
      await completeUpdateCustomerFlow.mutateAsync({ body });
      await refetchIdentity();
      setInfoStage(
        confirmingEmail ? Stage.FinishedEmailChange : Stage.FinishedPhoneChange,
      );
    };

    return (
      <UserInfoEditingWrapper
        title={t(`home.confirm_edit_account.title_${translationKey}`)}
        onSubmit={handleSubmitCode}
        backFunction={() =>
          setInfoStage(confirmingEmail ? Stage.EditEmail : Stage.EditPhone)}
      >
        <TextField
          key={3}
          label={t(`home.confirm_edit_account.code`)}
          focused
          value={confirmCode}
          onChange={handleCodeChange}
        />
        <LoadingButton
          type="submit"
          disabled={!canSubmitCode}
          loading={completeUpdateCustomerFlow.isPending}
          endIcon={<MUIIcon name="arrow_forward" />}
        >
          {t(`home.confirm_edit_account.confirm_change`)}
        </LoadingButton>
        <Typography
          color="error"
          onClick={() => setInfoStage(Stage.ChooseEdit)}
          sx={{ textDecoration: "underline", cursor: "pointer", mx: "auto" }}
        >
          {t(`home.confirm_edit_account.cancel_change`)}
        </Typography>
      </UserInfoEditingWrapper>
    );
  }

  const translationKey = infoStage === Stage.FinishedEmailChange
    ? "email"
    : "phone";
  return (
    <UserInfoEditingWrapper
      title={t(`home.confirm_edit_account.title_changed_${translationKey}`)}
      backFunction={() => setInfoStage(Stage.Viewing)}
    >
      <Typography>
        {t(`home.confirm_edit_account.subtitle_changed_${translationKey}`)}
      </Typography>
      <StaticButton onClick={() => setInfoStage(Stage.Viewing)}>
        {t("general.ok")}
      </StaticButton>
    </UserInfoEditingWrapper>
  );
};

interface ShortcutButton {
  href: string;
}

const ShortcutButton: FCWithChildren<ShortcutButton> = ({ href, children }) => (
  <Button
    sx={{
      width: "100%",
      p: "25px",
      bgcolor: "primary.light",
      justifyContent: "space-between",
      borderRadius: "5px",
    }}
    href={href}
    endIcon={<MUIIcon name="arrow_forward_ios" />}
  >
    {children}
  </Button>
);

const getDefaultInvoiceDateFrom = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  return date.toISOString().split("T")[0];
};

const Index = () => {
  const { t } = useTranslation();
  const greeting = useGreeting();

  const [invoiceDateFrom, setInvoiceDateFrom] = useState<string>(
    getDefaultInvoiceDateFrom(),
  );

  const invoices = apiHooks.useQuery("get", "/v1/invoice", {
    params: { query: { invoiceDateFrom } },
  });

  const {
    invoiceInfoTitle,
    invoiceInfoSubtitle,
    invoiceVariant,
    invoiceLoading,
  } = useMemo(() => {
    if (!invoices.data?.data?.salesInvoices) return { invoiceLoading: true };
    const now = Date.now();

    const openInvoices = invoices.data.data.salesInvoices
      .filter((invoice) => invoice.status === "Open")
      .sort(
        (a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
      );
    const lateOpenInvoices = openInvoices.filter(
      (invoice) => new Date(invoice.dueDate).getTime() < now,
    );

    if (lateOpenInvoices.length) {
      return {
        invoiceInfoTitle: t("home.invoice_info_title_late"),
        invoiceInfoSubtitle: `${t("home.invoice_info_subtitle_late")} ${
          dayjs(
            lateOpenInvoices[0].dueDate,
          ).format("DD.MM.YYYY")
        }`,
        invoiceVariant: "error" as const,
      };
    }

    if (openInvoices.length) {
      return {
        invoiceInfoTitle: t("home.invoice_info_title_open"),
        invoiceInfoSubtitle: `${t("home.invoice_info_subtitle_open")} ${
          dayjs(
            openInvoices[0].dueDate,
          ).format("DD.MM.YYYY")
        }`,
        invoiceVariant: "warning" as const,
      };
    }

    return {
      invoiceInfoTitle: t("home.invoice_info_title_none_open"),
      invoiceVariant: "success" as const,
    };
  }, [invoices.data, t]);

  return (
    <SubpageWrapper title={greeting} StackProps={{ sx: { px: 5 } }}>
      <UserInfo />
      <Stack gap={3} mt={4} mb={4} sx={{ width: "100%" }}>
        {/* {newestInfo && <InfoBar href={PATH_INFO} title={newestInfo} />} */}
        {!invoiceLoading && (
          <InfoBar
            href={PATH_INVOICES}
            title={invoiceInfoTitle}
            variant={invoiceVariant}
            subtitle={invoiceInfoSubtitle}
          />
        )}
        {
          /* {!newestInfo && !invoiceLoading && (
          <Typography
            my={3}
            textAlign="center"
            sx={(t) => ({ opacity: t.palette.action.disabledOpacity })}
          >
            {t("home.no_info")}
          </Typography>
        )} */
        }
      </Stack>
      <Stack direction={{ xs: "column", md: "row" }} gap={2} width="100%">
        <ShortcutButton href={PATH_METER_READING}>
          {t("home.to_meter_reading")}
        </ShortcutButton>
        <ShortcutButton href={PATH_SERVICE_REPORTS}>
          {t("home.to_service_reports")}
        </ShortcutButton>
        <ShortcutButton href={PATH_RELOCATE}>
          {t("home.to_relocate")}
        </ShortcutButton>
      </Stack>
    </SubpageWrapper>
  );
};

export default Index;
