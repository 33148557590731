import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIdentity, UseIdentityProps } from "./useIdentity";
import useReauthentication, {
  UseAuthenticationProps,
} from "./useReauthentication";

export interface UseAuthorizationOrRedirectProps
  extends UseIdentityProps, UseAuthenticationProps {
  redirect: string;
}

export const useAuthorizationOrRedirect = ({
  token,
  redirect,
  setToken,
}: UseAuthorizationOrRedirectProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const identity = useIdentity({ token });

  const invalidToken = !identity.isLoading && !identity.isIdentified;

  useEffect(() => {
    if (invalidToken) {
      navigate(redirect, { state: { from: location }, replace: true });
      setToken(null);
    }
  }, [invalidToken, location, navigate, redirect, setToken, token]);

  useReauthentication({
    token: invalidToken ? null : token,
    setToken,
  });

  return identity;
};
