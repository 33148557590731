import { Dispatch, SetStateAction, useRef, useState } from "react";
import { IS_DEV } from "../../variables";

const errorFn = IS_DEV
  ? () => console.warn("Cannot call setState in a controlled enviroment.")
  : () => void 0;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UndefinedOrAny = any;
export const useControlledElement = <T>(
  controlled: UndefinedOrAny,
  initial: T,
  valueFromProps: T = controlled,
): [state: T, setState: Dispatch<SetStateAction<T>>, isControlled: boolean] => {
  const { current: isControlled } = useRef(controlled !== undefined);
  const [v, setV] = useState<T>(initial);
  return isControlled ? [valueFromProps, errorFn, true] : [v, setV, false];
};
