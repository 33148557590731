import { apiHooks } from "../apis/norskGassnettApiHooks";

export interface UseIdentityProps {
  token: string | null;
}

export const useIdentity = ({ token }: UseIdentityProps) => {
  const identity = apiHooks.useQuery("get", "/v1/identity/me", {
    headers: { Authorization: `Bearer ${token}` },
  }, { enabled: !!token, retry: false, refetchOnWindowFocus: false });

  return {
    isLoading: identity.isLoading,
    isValidating: identity.isPending,
    isIdentified: identity.data?.data !== undefined,
    refetch: identity.refetch,
    identity: identity.data?.data?.current,
  };
};
