import React from "react";
import { Box, Link, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import StaticButton from "../atomics/StaticButton";
import TranslatedLink from "../complex/TranslatedLink";
import Logo from "../atomics/Logo";

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack
      component="footer"
      py={6}
      sx={{ bgcolor: "primary.main" }}
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      <Logo variant="white" maxHeight="4rem" maxWidth="100%" px={2} />
      <Stack direction="column" justifyContent="center" alignItems="center">
        <TranslatedLink
          color="primary.contrastText"
          content="footer.contact_number"
          sx={{ opacity: 0.7 }}
        />
        <TranslatedLink
          color="primary.contrastText"
          content="footer.contact_email"
          sx={{ opacity: 0.7 }}
        />
        <TranslatedLink
          color="primary.contrastText"
          content="footer.contact_address"
          linkProps={{ target: "_blank", rel: "noopener noreferrer" }}
          sx={{ opacity: 0.7 }}
        />
      </Stack>
      <StaticButton arrow="before" href={t("general.website_url")}>
        {t("general.to_website_url_text")}
      </StaticButton>
    </Stack>
  );
};

const DisclaimerBar: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack
      py={3}
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      <Link
        href={t("footer.to_policy_link")}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t("footer.to_policy_link_text")}
      </Link>
      <Box>
        {t("general.title")} &copy; {new Date().getFullYear()}
      </Box>
    </Stack>
  );
};

export { DisclaimerBar, Footer };
