import { useCallback, useEffect, useRef, useState } from "react";
import { apiHooks, Paths } from "../apis/norskGassnettApiHooks";
import type {
  PathsWithMethod as PathsWithMethodGeneric,
  SuccessResponseJSON,
} from "openapi-typescript-helpers";
import type { MaybeOptionalInit } from "openapi-fetch";

type GetPaths = PathsWithMethodGeneric<Paths, "get">;

type DataReturnedFrom<Endpoint extends GetPaths> =
  Paths[Endpoint]["get"] extends Record<string | number, unknown>
    ? SuccessResponseJSON<Paths[Endpoint]["get"]> extends { data: infer U } ? U
    : never
    : never;

type ExtendedQueryOptions<Endpoint extends GetPaths> =
  & MaybeOptionalInit<Paths[Endpoint], "get">
  & {
    [key: string]: unknown;
  };

type QueryOptions<Endpoint extends GetPaths> =
  ExtendedQueryOptions<Endpoint> extends {
    params?: {
      query?: infer U;
    };
  } ? Omit<U, "skip" | "take"> extends Record<string, never> ? never
    : Omit<U, "skip" | "take">
    : never;

/**
 * A hook that fetches data from an endpoint, building on already existing data for each time the function is called.
 *
 * @param endpoint The endpoint to query
 * @param itemIncrement The amount of items to fetch each time fetchMore is called
 * @param select A function that takes the raw data and returns an array of data
 * @param query Extra query params to pass to the endpoint
 */
export const useCumulativeQuery = <
  Endpoint extends GetPaths,
  RawType extends DataReturnedFrom<Endpoint>,
  ItemType,
>(
  endpoint: Endpoint,
  itemIncrement: number,
  select: (data: RawType) => ItemType[],
  ...query: QueryOptions<Endpoint> extends never ? [undefined?]
    : [QueryOptions<Endpoint>]
) => {
  // The query cursor
  const [skip, setSkip] = useState(0);

  // Cached, as none of these should ever change during the lifecycle of the component, and because
  // we want to keep the query object the same between renders
  const {
    current: {
      query: queryCache,
      select: selectCache,
      endpoint: endpointCache,
    },
  } = useRef({ query: query[0] ?? {}, select, endpoint });

  const { isPending, data: rawData, dataUpdatedAt, error } = apiHooks.useQuery(
    "get",
    endpointCache,
    {
      params: {
        query: {
          ...queryCache,
          take: itemIncrement,
          skip,
        },
      },
    } as ExtendedQueryOptions<Endpoint>,
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );

  const [lastAddedDataTimestamp, setLastAddedDataTimestamp] = useState<number>(
    0,
  );
  const [allData, setAllData] = useState<ItemType[] | null>();
  const [reachedEnd, setReachedEnd] = useState(false);

  // useEffect is used to only add data when we are sure the data has been updated
  useEffect(() => {
    if (lastAddedDataTimestamp < dataUpdatedAt && rawData) {
      const data = selectCache((rawData as unknown as { data: RawType }).data);
      if (data.length < itemIncrement) {
        setReachedEnd(true);
      }
      setAllData([...(allData ?? []), ...data]);
      setLastAddedDataTimestamp(dataUpdatedAt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdatedAt, lastAddedDataTimestamp]);

  const fetchMore = useCallback(() => setSkip((v) => v + itemIncrement), [
    itemIncrement,
  ]);

  return {
    data: allData,
    isPending,
    reachedEnd,
    error,
    fetchMore,
  };
};
