import {
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { useControlledElement } from "../../hooks/useControlledElement";
import MUIIcon from "../atomics/MUIIcon";
import { IS_DEV } from "../../../variables";

export type Option<T> = {
  label: string;
  value: T;
};

export interface OptionPickerProps<T = string>
  extends Omit<SelectProps<T>, "variant" | "onChange" | "defaultValue"> {
  options: Option<T>[];
  value?: T;
  onChange?: (value: T | null) => void;
  placeholder?: string;
  initialValue?: T;
  variant?: "color" | "white";
  scaleWidth?: boolean;
  textAlign?: "left" | "center" | "right";
}
const OptionPicker: React.FC<OptionPickerProps> = ({
  options,
  onChange,
  value,
  placeholder = "",
  initialValue,
  variant = "color",
  scaleWidth,
  sx = {},
  SelectDisplayProps = {},
  textAlign = "center",
  ...props
}) => {
  const theme = useTheme();
  const calculatedWidth = scaleWidth
    ? options.reduce(
      (a, c) => Math.max(a, c.label.length),
      placeholder.length,
    ) + "ch"
    : undefined;

  useEffect(() => {
    const originalLength = options.length;
    const setLength = new Set(options.map((o) => o.value)).size;
    if (IS_DEV && originalLength !== setLength) {
      console.warn("OptionPicker cannot have options with matching keys");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const indexOfOption = options.findIndex((o) => o.value === value);
  const initialIndex = initialValue != null
    ? options.findIndex((o) => o.value === initialValue)
    : -1;

  const [chosenOption, setChosenOption, controlled] = useControlledElement<
    number
  >(value, initialIndex, indexOfOption);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const index = +event.target.value;
    if (chosenOption === index || index === -1) return;
    if (!controlled) setChosenOption(index);

    const optionValue = index > -1 ? options[index].value : null;
    onChange?.(optionValue);
  };

  const colorTheme = variant === "color"
    ? {
      bgcolor: "primary.main",
      color: "primary.contrastText",
    }
    : {
      borderSize: 2,
      color: "primary.main",
    };
  return (
    <Select
      {...props}
      value={chosenOption.toString()}
      onChange={handleChange}
      size="small"
      renderValue={(i) => i === "-1" ? placeholder : options[parseInt(i)].label}
      sx={{
        "& .MuiSelect-select.MuiInputBase-input": {
          width: calculatedWidth,
        },
        borderRadius: 0,
        textAlign,
        ...colorTheme,
        ...sx,
      }}
      IconComponent={(p) => (
        <MUIIcon
          {...p}
          name="arrow_drop_down"
          sx={{
            color: `${
              theme.palette.primary[
                variant === "color" ? "contrastText" : "main"
              ]
            } !important`,
          }}
        />
      )}
      MenuProps={{
        sx: {
          ".MuiMenu-paper": {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
        },
      }}
      SelectDisplayProps={{
        style: {
          paddingTop: 6.5,
          paddingBottom: 6.5,
        },
        ...SelectDisplayProps,
      }}
    >
      {!!placeholder && (
        <MenuItem key="never" disabled value="-1">
          <em>{placeholder}</em>
        </MenuItem>
      )}
      {options.map(({ label }, i) => (
        <MenuItem key={i} value={i.toString()}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default OptionPicker;
