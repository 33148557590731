import {
  authMiddleware,
  components,
  createApiClient,
  paths,
} from "@ng/backend";
import { useStore } from "../../store/store";
import createClient from "openapi-react-query";

// We create and set up the api client here
const apiClient = createApiClient({ baseUrl: import.meta.env.VITE_API_URL });
apiClient.use(authMiddleware({
  getToken: async () => useStore.getState().auth.token ?? undefined,
}));

export const apiHooks = createClient(apiClient);

export type Paths = paths;
export type Schemas = components["schemas"];
