import { Box, Button, ButtonProps, CircularProgress } from "@mui/material";

export interface LoadingButtonProps extends ButtonProps {
  square?: boolean;
  loading?: boolean;
  loadingValue?: string;
  children?: React.ReactNode;
  placeItems?: "flex-start" | "center" | "flex-end";
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  square,
  children,
  startIcon,
  endIcon,
  loading,
  loadingValue,
  disabled,
  variant = "contained",
  placeItems = "center",
  sx,
  ...props
}) => {
  const iconSize = "20px";
  const sIcon =
    (startIcon && loading && <CircularProgress size={iconSize} />) || startIcon;
  const eIcon = (endIcon && loading && <CircularProgress size={iconSize} />) ||
    endIcon;
  const replaceMiddle = !startIcon && !endIcon;
  const squareSx = square
    ? {
      borderRadius: "6px",
      px: 2,
    }
    : {};

  const disable = loading || disabled;
  return (
    <Button
      {...props}
      onClick={disable ? undefined : props.onClick}
      variant={variant}
      startIcon={sIcon}
      endIcon={eIcon}
      disabled={disable}
      disableElevation
      sx={{
        color: loading && replaceMiddle ? "transparent !important" : undefined,
        px: 3,
        py: 0.5,
        display: "flex",
        justifyContent: replaceMiddle ? placeItems : "space-between",
        ...squareSx,
        ...sx,
      }}
    >
      <Box
        sx={(t) => ({
          position: "absolute",
          visibility: loading && replaceMiddle ? "visible" : "hidden",
          left: "50%",
          transform: "translate(-50%)",
          color: t.palette.action.disabled,
        })}
      >
        {loadingValue || <CircularProgress size={iconSize} />}
      </Box>
      {children}
    </Button>
  );
};

export default LoadingButton;
