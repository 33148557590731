import { Outlet } from "react-router-dom";
import { useStore } from "../store/store";
import { useAuthorizationOrRedirect } from "../lib/hooks/useAuthorizationOrRedirect";
import { PATH as PATH_LOGIN } from "./__anonymous/login";
import { useEffect, useMemo } from "react";
import {
  IdentifiedContextType,
  IdentifiedProvider,
} from "../lib/contexts/IdentifiedContext";
import { Schemas } from "../lib/apis/norskGassnettApiHooks";
import * as Sentry from "@sentry/react";
import Loader from "../lib/components/atomics/Loader";
import PageWrapper from "../lib/components/atomics/PageWrapper";
import AddPhoneDialog from "../lib/components/complex/AddPhoneDialog";

export default function Authenticated() {
  const token = useStore(({ auth }) => auth.token);
  const setToken = useStore(({ auth }) => auth.setToken);

  const authorization = useAuthorizationOrRedirect({
    token,
    redirect: PATH_LOGIN,
    setToken,
  });

  const providerValue = useMemo<IdentifiedContextType | null>(() => {
    if (authorization.isLoading) return null;
    if (!authorization.isIdentified) return null;
    if (!token) return null;

    return {
      identity: authorization.identity as Schemas["MeResponseBody"]["current"],
      refetchIdentity: authorization.refetch,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorization]);

  useEffect(() => {
    if (providerValue?.identity) {
      Sentry.setUser({ id: providerValue.identity.id });
    }
  }, [providerValue?.identity]);

  if (!providerValue) {
    return (
      <PageWrapper centerContent>
        <Loader variant="container" />
      </PageWrapper>
    );
  }

  return (
    <IdentifiedProvider value={providerValue}>
      <PageWrapper appendCustomerHeader>
        <AddPhoneDialog />
        <Outlet />
      </PageWrapper>
    </IdentifiedProvider>
  );
}
