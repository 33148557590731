import React, { useMemo, useState } from "react";
import {
  AlertColor,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  dateFormat,
  getAddressStringForCustomer,
  getBorderRadius,
} from "../../lib/helpers/utils";
import SubpageWrapper from "../../lib/components/atomics/SubpageWrapper";
import { useIdentifiedContext } from "../../lib/contexts/IdentifiedContext";
import { apiHooks, Schemas } from "../../lib/apis/norskGassnettApiHooks";
import Loader from "../../lib/components/atomics/Loader";
import dayjs from "dayjs";
import LoadingButton from "../../lib/components/atomics/LoadingButton";
import MUIIcon from "../../lib/components/atomics/MUIIcon";
import CollapsableAlert from "../../lib/components/complex/CollapsableAlert";
import { useErrorMessage } from "../../lib/hooks/useErrorMessage";

export const PATH_SEGMENT = "avlesning";
export const PATH = `/${PATH_SEGMENT}`;
export const getPath = () => PATH;

interface InfoTableRowProps {
  title: string;
  data?: string | number | null;
  noDivider?: boolean;
}

const InfoTableRow: React.FC<InfoTableRowProps> = ({
  title,
  data,
  noDivider,
}) => (
  <>
    <Stack
      direction={{ xs: "column", sm: "row" }}
      gap={2}
      gridTemplateRows="1fr 1fr"
      alignItems="center"
      sx={{ "& > *": { width: 1, textAlign: { xs: "center", sm: "left" } } }}
    >
      <Typography variant="h5">{title}</Typography>
      <Typography variant="body1">{data}</Typography>
    </Stack>
    {!noDivider && <Divider sx={{ display: { sm: "none" } }} />}
  </>
);

const MeterReading: React.FC = () => {
  const { t } = useTranslation();
  const er = useErrorMessage();
  const { identity } = useIdentifiedContext();

  const [meterValue, setMeterValue] = useState("");
  const [meterAlert, setMeterAlert] = useState<string | null>(null);
  const [meterSeverity, setMeterSeverity] = useState<AlertColor>("success");

  const { currentTime, pastTime } = useMemo(() => {
    const now = new Date();

    const tomorrow = new Date(now);
    tomorrow.setDate(now.getDate() + 1);

    const pastTime = new Date(
      now.getFullYear() - 2,
      now.getMonth(),
      now.getDate(),
    );

    return {
      currentTime: dateFormat(tomorrow),
      pastTime: dateFormat(pastTime),
    };
  }, []);

  const meterReadings = apiHooks.useQuery("get", "/v1/meter-reading", {
    params: {
      query: {
        dateFrom: pastTime,
        dateTo: currentTime,
      },
    },
  });

  const createMeterReading = apiHooks.useMutation("post", "/v1/meter-reading");

  const sortedMeterReadings = useMemo(() => {
    if (!meterReadings.data) return [];
    const sorted = [...meterReadings.data.data.meterReadings].sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
    );

    return sorted.slice(0, 5);
  }, [meterReadings]);

  const consumptions = apiHooks.useQuery(
    "get",
    "/v1/meter-reading/consumption",
    {
      params: {
        query: {
          dateFrom: pastTime,
          dateTo: currentTime,
        },
      },
    },
  );
  const { newestConsumption, newestNonEstimateConsumption } = useMemo(() => {
    const newestConsumption = {
      consumption: null as
        | Schemas["ListConsumptionsResponseBody"]["consumptions"][number]
        | null,
      date: -Infinity,
    };
    const newestNonEstimateConsumption = {
      consumption: null as
        | Schemas["ListConsumptionsResponseBody"]["consumptions"][number]
        | null,
      date: -Infinity,
    };
    if (consumptions.data) {
      for (const consumption of consumptions.data.data.consumptions) {
        const consumptionDate = new Date(consumption.readingDate).getTime();
        if (consumptionDate > newestConsumption.date) {
          newestConsumption.consumption = consumption;
          newestConsumption.date = consumptionDate;
        }
        if (
          !consumption.estimate &&
          consumptionDate > newestNonEstimateConsumption.date
        ) {
          newestNonEstimateConsumption.consumption = consumption;
          newestNonEstimateConsumption.date = consumptionDate;
        }
      }
    }

    return {
      newestConsumption: newestConsumption.consumption,
      newestNonEstimateConsumption: newestNonEstimateConsumption.consumption,
    };
  }, [consumptions]);

  const handleChangeMeterReading = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const matches = /^\d*$/.test(value);
    if (matches) setMeterValue(value);
  };

  return (
    <SubpageWrapper title={t("meter.header")} StackProps={{ gap: 2 }}>
      {meterReadings.isLoading ? <Loader variant="container" /> : (
        <>
          <Paper
            component={Stack}
            gap={2}
            sx={{ p: "45px", width: "100%", borderRadius: getBorderRadius }}
          >
            <InfoTableRow
              title={t("meter.user_id")}
              data={identity.customerNo}
            />
            <InfoTableRow
              title={t("meter.meter_id")}
              data={identity.meterNumber}
            />
            <InfoTableRow
              title={t("meter.adress_label")}
              data={getAddressStringForCustomer(identity)}
              noDivider
            />
            <Divider sx={{ my: 1 }} />
            <InfoTableRow
              title={t("meter.last_registration_date")}
              data={newestConsumption
                ? dayjs(newestConsumption.readingDate)
                  .format("D MMMM YYYY")
                  .toUpperCase()
                : t("meter.no_last_reading")}
            />
            <InfoTableRow
              title={t("meter.last_registration_reading")}
              data={newestConsumption?.meterReading ??
                t("meter.no_last_reading")}
              noDivider
            />
          </Paper>
          <Paper
            sx={{ p: "45px", width: "100%", borderRadius: getBorderRadius }}
          >
            <Stack
              direction="column"
              alignItems="center"
              gap={3}
              sx={{ mx: "auto", width: "fit-content" }}
            >
              <Typography variant="h3" textAlign="center">
                {t("meter.new_registration_reading")}
              </Typography>
              <Stack
                direction="column"
                alignItems="center"
                gap={3}
                sx={{ mx: "auto", width: "fit-content" }}
              >
                <Typography
                  variant="body1"
                  sx={{ width: "max-content", fontStyle: "italic" }}
                >
                  {t("meter.new_registration_date").replace(
                    "{{date}}",
                    dayjs().format("DD.MM.YYYY"),
                  )}
                </Typography>
                <TextField
                  fullWidth
                  label={t("meter.input")}
                  variant="outlined"
                  helperText={t("meter.warning_no_fractions")}
                  value={meterValue}
                  onChange={handleChangeMeterReading}
                  inputProps={{ inputMode: "numeric" }}
                  type="string"
                />
                <LoadingButton
                  endIcon={<MUIIcon name="arrow_forward" />}
                  fullWidth
                  disabled={!meterValue || !!createMeterReading.error}
                  loading={createMeterReading.isPending}
                  onClick={async () => {
                    setMeterAlert(null);
                    const latestReading =
                      newestNonEstimateConsumption?.meterReading ?? 0;
                    const newReading = parseInt(meterValue);
                    if (newReading < latestReading) {
                      setMeterSeverity("error");
                      setMeterAlert(t("meter.cannot_be_less"));
                      return;
                    }
                    await createMeterReading.mutateAsync({
                      body: {
                        meterReading: newReading,
                        readingDate: dateFormat(),
                      },
                    });
                    await meterReadings.refetch();
                    setMeterSeverity("success");
                    setMeterAlert(t("meter.success"));
                    setMeterValue("");
                  }}
                >
                  {t("meter.submit")}
                </LoadingButton>
                <CollapsableAlert
                  severity={createMeterReading.error ? "error" : meterSeverity}
                  alert={meterAlert ||
                    er("meter", createMeterReading.error ?? undefined)}
                />
              </Stack>
            </Stack>
          </Paper>
          <Paper
            component={Stack}
            gap={2}
            sx={{ p: "45px", width: "100%", borderRadius: getBorderRadius }}
          >
            <Typography variant="h3" textAlign="center">
              {t("meter.latest_readings")}
            </Typography>
            <Table
              sx={{
                width: 300,
                maxWidth: "100%",
                mx: "auto",
                mt: 3,
                "& :is(td, th)": { width: "50%" },
              }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="right">{t("meter.reading_date")}</TableCell>
                  <TableCell align="left">{t("meter.reading_value")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedMeterReadings.map(({ date, meterReading }, i) => (
                  <React.Fragment key={i}>
                    <TableRow>
                      <TableCell colSpan={2} sx={{ p: 0 }}>
                        <Divider />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        align="right"
                        sx={{ pl: 0 }}
                      >
                        {dayjs(date).format("D MMMM YYYY")}
                      </TableCell>
                      <TableCell sx={{ pr: 0 }}>{meterReading}</TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </>
      )}
    </SubpageWrapper>
  );
};

export default MeterReading;
