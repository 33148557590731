import { Outlet } from "react-router-dom";
import PageWrapper from "../lib/components/atomics/PageWrapper";

export default function Anonymous() {
  return (
    <PageWrapper centerContent>
      <Outlet />
    </PageWrapper>
  );
}
