import { Box, Stack } from "@mui/material";
import { DisclaimerBar, Footer } from "../complex/Footer";
import Header from "../complex/Header";
import { SIZE_MD, SIZE_SM, SIZE_XS } from "../../../constants";
import HomeHeader from "../complex/HomeHeader";
import { FCWithChildren } from "../../../types";

interface PageWrapperProps {
  appendCustomerHeader?: boolean;
  centerContent?: boolean;
}

const PageWrapper: FCWithChildren<PageWrapperProps> = ({
  appendCustomerHeader,
  centerContent,
  children,
}) => (
  <>
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
        // justifyContent: "space-between",
      }}
    >
      <Header />
      {appendCustomerHeader && <HomeHeader />}
      <Stack
        justifyContent={centerContent ? "center" : undefined}
        alignItems="center"
        sx={{ width: "100%", flexGrow: 1 }}
      >
        <Stack
          direction="column"
          alignItems="center"
          sx={{
            my: { xs: 5, sm: 8 },
            width: { xs: SIZE_XS, sm: SIZE_SM, md: SIZE_MD },
          }}
        >
          {children}
        </Stack>
      </Stack>
    </Box>
    <Footer />
    <DisclaimerBar />
  </>
);
export default PageWrapper;
