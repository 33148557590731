import { useEffect, useRef } from "react";
import { apiHooks } from "../apis/norskGassnettApiHooks";
import { TOKEN_AUTHORIZATION_VALIDITY } from "@ng/backend";

export interface UseAuthenticationProps {
  token: string | null;
  setToken: (value: string | null) => void;
}

const useReauthentication = ({ token, setToken }: UseAuthenticationProps) => {
  const reauthenticate = apiHooks.useQuery(
    "get",
    "/v1/identity/reauthenticate",
    undefined,
    {
      enabled: !!token,
      refetchInterval: TOKEN_AUTHORIZATION_VALIDITY * 0.9,
      retry: 1,
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: true,
    },
  );

  useEffect(() => {
    if (reauthenticate.isLoading || reauthenticate.isPending) {
      return;
    } else if (reauthenticate.error) {
      setToken(null);
    } else if (reauthenticate.data.data.token) {
      setToken(reauthenticate.data.data.token);
    }
  }, [reauthenticate, setToken]);
};

export default useReauthentication;
