import { Group } from "@visx/group";
import { BarGroup, BarGroupHorizontal } from "@visx/shape";
import { AxisBottom, AxisLeft, AxisTop, TickFormatter } from "@visx/axis";
import { NumberLike, scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { ParentSize } from "@visx/responsive";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { GridColumns, GridRows } from "@visx/grid";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { Tooltip, useTooltip } from "@visx/tooltip";
import {
  Accessor,
  BarGroup as BarGroupProps,
  BarGroupBar as BarGroupBarProps,
  BarGroupHorizontal as BarGroupHorizontalProps,
} from "@visx/shape/lib/types";
import { Text } from "@visx/text";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import type { ScaleBand, ScaleLinear, ScaleOrdinal } from "d3-scale";
import { useIsIdentityLng } from "../../hooks/useIsIdentityLng";

const KG_PER_1M3 = 2.03;
const kgToM3 = (kg: number) => Math.round((100 * kg) / KG_PER_1M3) / 100;
const m3ToKg = (m3: number) => Math.round(100 * m3 * KG_PER_1M3) / 100;

const numTicks = 5; // only approximate

const circleSize = 15;

const margin = { top: 40, right: 40, bottom: 40, left: 50 };
const xsHeight = 300;
const xsLargeHeight = 600;
const smHeight = 250;
const estimateGrouping = Symbol("Estimate Grouping");

const SvgCircle = ({ color }: { color?: string }) => (
  <svg width={circleSize} height={circleSize}>
    <circle
      fill={color || "black"}
      r={circleSize / 2}
      cx={circleSize / 2}
      cy={circleSize / 2}
    />
  </svg>
);

interface EventWitMouseCoords {
  clientX: number;
  clientY: number;
}
type TooltipData =
  | {
    color?: string;
    m3: number | string;
    kg?: number | string;
  }[]
  | null;

const GraphToolbar = ({
  tooltipLeft,
  tooltipTop,
  tooltipData,
}: {
  tooltipLeft: number;
  tooltipTop: number;
  tooltipData: TooltipData;
}) => {
  const theme = useTheme();
  if (!tooltipData) return null;
  const dividerBorder = `2px solid ${theme.palette.divider}`;
  const includeKg = tooltipData.some((d) => d.kg !== undefined);
  return (
    <Tooltip
      left={tooltipLeft - 12}
      top={tooltipTop - 20}
      style={{
        pointerEvents: "none",
        position: "absolute",
        transform: "translate(-50%, -100%)",
        backgroundColor: theme.palette.background.paper,
        border: `solid 2px ${theme.palette.secondary.main}`,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "100%",
          left: "50%",
          transform: "translateY(-1px) rotate(45deg) translateX(-50%)",
          border: `solid 2px ${theme.palette.secondary.main}`,
          borderTop: "none",
          borderLeft: "none",
          width: "1rem",
          height: "1rem",
          bgcolor: "background.paper",
        }}
      />
      <TableContainer>
        <Table
          size="small"
          sx={{
            "& td:nth-of-type(2), & th:nth-of-type(3)": {
              borderLeft: dividerBorder,
              fontWeight: theme.typography.fontWeightLight,
            },
          }}
        >
          <TableHead>
            <TableRow sx={{ "& th": { borderBottom: dividerBorder } }}>
              <TableCell></TableCell>
              {includeKg && <TableCell align="right">kg</TableCell>}
              <TableCell align="right">m3</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tooltipData.map((row) => (
              <TableRow
                key={row.color}
                sx={{ "& :is(td, th)": { borderBottom: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <SvgCircle color={row.color} />
                </TableCell>
                {includeKg && <TableCell align="right">{row.kg || 0}
                </TableCell>}
                <TableCell align="right">{row.m3}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Tooltip>
  );
};

type GroupingValues = "tertiary" | "monthly";

type GroupValuesData = {
  readingDate: string;
  meterReading: number;
  estimate: boolean;
}[];

type GroupedValue = {
  group: string;
  [estimate: symbol]: {
    [key: number]: boolean;
  };
  [key: number]: number;
};

/**
 * Changes the meterReading to deltas between the readings besides the first one.
 */
const calculateDeltas = (data: GroupValuesData): GroupValuesData => {
  if (!data) return [];
  const sortedData = data.sort((a, b) => {
    const aDate = new Date(a.readingDate);
    const bDate = new Date(b.readingDate);
    return aDate.getTime() - bDate.getTime();
  });
  const deltas = sortedData.map((reading, i, data) => {
    if (i === 0) return reading;
    const prevReading = data[i - 1];
    return {
      ...reading,
      meterReading: reading.meterReading - prevReading.meterReading,
    };
  });

  return deltas;
};
/**
 * Groups the data into groups of 3 or 12 months for the past 3 years.
 * As we are using deltas to calculate the usage, we need to add the deltas together for a period.
 */
const groupValues = (
  data: GroupValuesData,
  grouping: GroupingValues = "tertiary",
) => {
  if (!data) return [];
  const values: GroupedValue[] = [];
  const groupingLength = grouping === "tertiary" ? 3 : 12;

  const newestYear = new Date().getFullYear();

  for (let i = 1; i <= groupingLength; i++) {
    const group = grouping === "tertiary" ? "T" + i : dayjs("2022-" + i)
      .format("MMMM")
      .substring(0, 3)
      .toUpperCase();

    values.push({
      group,
      [newestYear - 2]: 0,
      [newestYear - 1]: 0,
      [newestYear]: 0,
      [estimateGrouping]: {
        [newestYear - 2]: false,
        [newestYear - 1]: false,
        [newestYear]: false,
      },
    });
  }

  for (let i = 0; i < data.length; i++) {
    const { readingDate, meterReading, estimate } = data[i];
    const [valid, y, m] = readingDate.match(/^(\d{4})-(\d{2})-\d{2}$/) || [];
    if (!valid) continue;
    const year = +y,
      month = +m;
    if (year + 2 < newestYear) continue;
    const groupIndex = grouping === "tertiary"
      ? Math.ceil(month / 4) - 1
      : month - 1;
    const group = values[groupIndex];
    group[year] = group[year] + meterReading;
    if (estimate) group[estimateGrouping][year] = true;
  }
  return values;
};

const numberFormatter: TickFormatter<NumberLike> = (n, i, a) => {
  const useK =
    a.reduce((prev, curr) => Math.max(prev, curr.value.valueOf()), 0) >= 2000;
  const prev = a[i - 1];
  if (!useK) return n + "";
  if (
    prev &&
    Math.floor(n.valueOf() / 1000) === Math.floor(prev.value.valueOf() / 1000)
  ) {
    return "";
  }
  return `${Math.floor(n.valueOf() / 1000)}k`;
};

const isEstimate = (
  data: GroupedValue[] | null,
  groupI: number,
  key: string,
) => {
  return data && data[groupI][estimateGrouping][+key];
};

interface GasBarGraphLinesProps {
  type: "vertical" | "horizontal";
  scale: ScaleLinear<number, number>;
  width: number;
  height: number;
  theme: Theme;
}
const GasBarGraphLines = ({
  type = "vertical",
  scale,
  width,
  height,
  theme,
}: GasBarGraphLinesProps) => {
  const GridLines = type === "vertical" ? GridRows : GridColumns;
  return (
    <GridLines
      numTicks={numTicks}
      scale={scale}
      width={width}
      height={height}
      stroke={theme.palette.divider}
    />
  );
};

interface GasBarGraphAxisProps {
  type: "vertical" | "horizontal";
  usageScale: ScaleLinear<number, number>;
  timeScale: ScaleBand<string>;
  usableHeight: number;
  theme: Theme;
}
const GasBarGraphAxis = ({
  type = "vertical",
  theme,
  usableHeight,
  usageScale,
  timeScale,
}: GasBarGraphAxisProps) => {
  if (type === "vertical") {
    return (
      <>
        <AxisLeft
          scale={usageScale}
          hideAxisLine
          hideTicks
          numTicks={numTicks}
          tickLabelProps={() => ({
            // fontSize: 11,
            fontFamily: theme.typography.fontFamily,
            fill: theme.palette.text.primary,
            textAnchor: "end",
            dy: "0.33em",
          })}
          tickFormat={numberFormatter}
        />
        <AxisBottom
          top={usableHeight}
          scale={timeScale}
          hideAxisLine
          hideTicks
          tickLabelProps={() => ({
            // fontSize: 11,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            fill: theme.palette.text.primary,
            textAnchor: "middle",
          })}
        />
      </>
    );
  }

  return (
    <>
      <AxisLeft
        // top={usableHeight}
        scale={timeScale}
        hideAxisLine
        hideTicks
        tickLabelProps={() => ({
          // fontSize: 11,
          fontFamily: theme.typography.fontFamily,
          fontWeight: theme.typography.fontWeightMedium,
          fill: theme.palette.text.primary,
          textAnchor: "end",
          dy: "0.33em",
        })}
      />
      <AxisTop
        scale={usageScale}
        hideAxisLine
        hideTicks
        numTicks={numTicks}
        tickLabelProps={() => ({
          // fontSize: 11,
          fontFamily: theme.typography.fontFamily,
          fill: theme.palette.text.primary,
          textAnchor: "middle",
        })}
        tickFormat={numberFormatter}
      />
    </>
  );
};

interface GasBarGraphTildaProps {
  type: "vertical" | "horizontal";
  data: GroupedValue[];
  groupI: number;
  theme: Theme;
  barGroup: BarGroupProps<string> | BarGroupHorizontalProps<string>;
  bar: BarGroupBarProps<string>;
}

const GasBarGraphTilda = ({
  type,
  data,
  groupI,
  bar,
  barGroup,
  theme,
}: GasBarGraphTildaProps) => {
  if (!isEstimate(data, groupI, bar.key)) return null;

  const shouldPop = bar.height < bar.width;
  let color = theme.palette.primary.main;
  if (
    (type === "vertical" && bar.height > bar.width) ||
    (type === "horizontal" && bar.height < bar.width)
  ) {
    const contrastColor = theme.palette.getContrastText(bar.color);
    if (contrastColor === "#fff") color = "#fff";
  }

  const props = type === "vertical"
    ? {
      fontSize: bar.width,
      textAnchor: "middle" as const,
      verticalAnchor: "start" as const,
      x: bar.x + bar.width / 2,
      y: bar.y + (shouldPop ? -bar.width : 0),
    }
    : {
      fontSize: bar.height,
      textAnchor: "end" as const,
      verticalAnchor: "middle" as const,
      x: bar.x +
        bar.width -
        bar.height / 3 +
        (bar.height > bar.width ? bar.height : 0),
      y: bar.y + bar.height / 2,
    };

  return (
    <Text
      key={`bar-group-estimate-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
      fill={color}
      {...props}
    >
      ~
    </Text>
  );
};

interface GasBarGraphGroupProps {
  type: "vertical" | "horizontal";
  barGroup: BarGroupProps<string> | BarGroupHorizontalProps<string>;
  handleMouseDown: (
    event: EventWitMouseCoords,
    bars:
      | Omit<BarGroupProps<string>, "x0">
      | Omit<BarGroupHorizontalProps<string>, "y0">,
  ) => void;
  handleMouseLeave: () => void;
  data: GroupedValue[];
  groupI: number;
  theme: Theme;
}

const GasBarGraphGroup = ({
  type,
  barGroup,
  handleMouseDown,
  handleMouseLeave,
  data,
  groupI,
  theme,
}: GasBarGraphGroupProps) => {
  const extraProps = "y0" in barGroup
    ? { top: barGroup.y0 }
    : { left: barGroup.x0 };
  const firstBar = barGroup.bars[0];
  const lastBar = barGroup.bars.at(-1);
  let hoverX = firstBar?.x / 2 || 0;
  let hoverY = 0;
  let hoverWidth: string | number = lastBar ? lastBar.x + lastBar.width : 0;
  let hoverHeight = lastBar ? lastBar.y + lastBar.height : 0;
  if (type === "horizontal") {
    hoverX = 0;
    hoverY = firstBar?.y / 2 || 0;
    hoverWidth = "100%";
    hoverHeight = lastBar ? lastBar.y + lastBar.height : 0;
  }
  return (
    <Group key={`bar-group-${barGroup.index}`} {...extraProps}>
      {barGroup.bars.map((bar) => (
        <React.Fragment
          key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
        >
          <rect
            // key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
            x={bar.x}
            y={bar.y}
            width={bar.width}
            height={bar.height}
            fill={bar.color}
          />
          <GasBarGraphTilda
            type={type}
            data={data}
            groupI={groupI}
            bar={bar}
            barGroup={barGroup}
            theme={theme}
          />
        </React.Fragment>
      ))}
      <rect
        x={hoverX}
        y={hoverY}
        width={hoverWidth}
        height={hoverHeight}
        fill="transparent"
        onMouseDown={(e: EventWitMouseCoords) => handleMouseDown(e, barGroup)}
        onMouseLeave={handleMouseLeave}
      />
    </Group>
  );
};

interface GasBarGraphBarGroupProps
  extends Omit<GasBarGraphGroupProps, "barGroup" | "groupI"> {
  type: "vertical" | "horizontal";
  keys: string[];
  height: number;
  width: number;
  x0: Accessor<GroupedValue, string>;
  x0Scale: ScaleBand<string>;
  x1Scale: ScaleBand<string>;
  yScale: ScaleLinear<number, number>;
  color: ScaleOrdinal<string, string>;
}

const GasBarGraphBarGroup = ({
  type,
  data,
  keys,
  height,
  width,
  x0,
  x0Scale,
  x1Scale,
  yScale,
  color,
  theme,
  handleMouseDown,
  handleMouseLeave,
}: GasBarGraphBarGroupProps) => {
  const generalProps = {
    data,
    keys,
    height,
    width,
    color,
    children: (
      barGroups: BarGroupProps<string>[] | BarGroupHorizontalProps<string>[],
    ) =>
      barGroups.map((barGroup, groupI) => (
        <GasBarGraphGroup
          key={groupI}
          type={type}
          data={data}
          theme={theme}
          barGroup={barGroup}
          groupI={groupI}
          handleMouseDown={handleMouseDown}
          handleMouseLeave={handleMouseLeave}
        />
      )),
  };

  if (type === "horizontal") {
    return (
      <BarGroupHorizontal
        {...generalProps}
        y0={x0}
        y0Scale={x0Scale}
        y1Scale={x1Scale}
        xScale={yScale}
      />
    );
  }

  return (
    <BarGroup
      {...generalProps}
      x0={x0}
      x0Scale={x0Scale}
      x1Scale={x1Scale}
      yScale={yScale}
    />
  );
};

const GasBarGraph = ({
  data: ungroupedData,
  grouping = "tertiary",
}: {
  data: GroupValuesData;
  grouping?: GroupingValues;
}) => {
  const { t } = useTranslation();
  const isLng = useIsIdentityLng();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const type = isMobile ? "horizontal" : "vertical";
  const stackRef = useRef<HTMLElement>(null);
  const tooltipTimeout = useRef<number | null>(null);
  useEffect(() => {
    return () => {
      if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
    };
  }, []);

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<TooltipData>({
    tooltipOpen: false,
    tooltipData: null,
  });

  const { data, keys, timeScale, groupingScale, usageScale, colorScale } =
    useMemo(() => {
      if (!ungroupedData) return { data: null };

      const calculatedDeltas = calculateDeltas(ungroupedData);
      const data = groupValues(calculatedDeltas, grouping);

      const keys = Object.keys(data[0]).slice(0, -1);

      const timeScale = scaleBand<string>({
        domain: data.map((d) => d.group),
        padding: 0.2,
      });
      const groupingScale = scaleBand<string>({
        domain: keys,
        padding: 0.1,
      });
      const highestUsage = Math.max(
        ...data.map((d) =>
          Math.max(...keys.map((key) => parseInt(d[key as keyof GroupedValue])))
        ),
      );
      const usageScale = scaleLinear<number>({
        domain: [0, highestUsage],
      });
      const colorScale = scaleOrdinal<string, string>({
        domain: keys,
        range: [
          theme.palette.primary.light,
          theme.custom.usageBar,
          theme.palette.primary.main,
        ],
      });
      return {
        data,
        keys,
        timeScale,
        groupingScale,
        usageScale,
        colorScale,
      };
    }, [ungroupedData, grouping, theme]);
  const handleMouseDown = useCallback(
    (event: EventWitMouseCoords, bars: Omit<BarGroupProps<string>, "x0">) => {
      if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
      const { left = 0, top = 0 } =
        stackRef.current?.getBoundingClientRect?.() || {};
      const containerX = (event.clientX || 0) - left;
      const containerY = (event.clientY || 0) - top;
      showTooltip({
        tooltipLeft: containerX,
        tooltipTop: containerY,
        tooltipData: bars.bars.map((bar) => {
          const v = bar.value;
          const estimatePrefix = isEstimate(data, bars.index, bar.key)
            ? "~"
            : "";
          return {
            color: colorScale?.(bar.key),
            kg: estimatePrefix + isLng ? m3ToKg(v) : v,
            m3: estimatePrefix + isLng ? v : kgToM3(v),
          };
        }),
      });
    },
    [showTooltip, colorScale, data, isLng],
  );

  const handleMouseLeave = useCallback(() => {
    tooltipTimeout.current = window.setTimeout(() => {
      hideTooltip();
    }, 1000);
  }, [hideTooltip]);

  if (!data) return null;

  return (
    <Stack mt={3} sx={{ position: "relative" }} ref={stackRef}>
      <LegendOrdinal
        scale={colorScale}
        labelFormat={(label) => `${label.toUpperCase()}`}
      >
        {(labels) => (
          <Stack gap={2} direction="row" justifyContent="center">
            {labels.map((label, i) => (
              <LegendItem key={`legend-${i}`} margin="0 5px">
                <SvgCircle color={label.value} />
                <LegendLabel align="left" margin={theme.spacing(0, 0, 0, 1)}>
                  {label.text}
                </LegendLabel>
              </LegendItem>
            ))}
          </Stack>
        )}
      </LegendOrdinal>
      <ParentSize className="graph-container" debounceTime={50}>
        {({ width }) => {
          const height = isMobile
            ? grouping === "monthly" ? xsLargeHeight : xsHeight
            : smHeight;
          const usableWidth = width - margin.left - margin.right;
          const usableHeight = height - margin.top - margin.bottom;

          if (isMobile) {
            timeScale.rangeRound([0, usableHeight]);
            groupingScale.rangeRound([0, timeScale.bandwidth()]);
            usageScale.range([0, usableWidth]);
          } else {
            timeScale.rangeRound([0, usableWidth]);
            groupingScale.rangeRound([0, timeScale.bandwidth()]);
            usageScale.range([usableHeight, 0]);
          }

          return width < 10 ? null : (
            <svg width={width} height={height}>
              <Group top={margin.top} left={margin.left}>
                <GasBarGraphLines
                  type={type}
                  theme={theme}
                  scale={usageScale}
                  width={usableWidth}
                  height={usableHeight}
                />
                {ungroupedData.length && (
                  <GasBarGraphBarGroup
                    type={type}
                    data={data}
                    keys={keys}
                    height={usableHeight}
                    width={usableWidth}
                    x0={(d) => d.group}
                    x0Scale={timeScale}
                    x1Scale={groupingScale}
                    yScale={usageScale}
                    color={colorScale}
                    theme={theme}
                    handleMouseDown={handleMouseDown}
                    handleMouseLeave={handleMouseLeave}
                  />
                )}
                <GasBarGraphAxis
                  type={type}
                  theme={theme}
                  usageScale={usageScale}
                  timeScale={timeScale}
                  usableHeight={usableHeight}
                />
              </Group>
            </svg>
          );
        }}
      </ParentSize>
      <Typography textAlign="center">{t("usage.tilda_is_estimate")}</Typography>
      {tooltipOpen && tooltipData && (
        <GraphToolbar
          tooltipLeft={tooltipLeft}
          tooltipTop={tooltipTop}
          tooltipData={tooltipData}
        />
      )}
    </Stack>
  );
};

export default GasBarGraph;
