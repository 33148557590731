import { createContext, useContext } from "react";
import { Schemas } from "../apis/norskGassnettApiHooks";

export type IdentifiedContextType = {
  identity: Schemas["MeResponseBody"]["current"];

  refetchIdentity: () => Promise<unknown>;
};

const IdentifiedContext = createContext<IdentifiedContextType>(
  {} as IdentifiedContextType,
);

export const IdentifiedProvider = IdentifiedContext.Provider;

export const useIdentifiedContext = () => useContext(IdentifiedContext);
