import React from "react";
import { CircularProgress, Stack } from "@mui/material";

interface LoaderProps {
  variant?: "page" | "container" | "padded";
}

const Loader: React.FC<LoaderProps> = ({ variant = "padded" }) => {
  if (variant === "page") {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", height: "100vh" }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        width: "100%",
        height: variant === "container" ? 1 : undefined,
        p: variant === "padded" ? 3 : 0,
      }}
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Stack>
  );
};

export default Loader;
