import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  StackProps,
  SxProps,
  Typography,
} from "@mui/material";
import { useStore } from "../../../store/store";
import { useTranslation } from "react-i18next";
import StaticButton from "../atomics/StaticButton";
import MUIIcon from "../atomics/MUIIcon";
import Logo from "../atomics/Logo";
import { FCWithChildren } from "../../../types";
import { PATH as PATH_ROOT } from "../../../routes/root";
import { apiHooks, Schemas } from "../../apis/norskGassnettApiHooks";
import { stringToColor } from "../../helpers/utils";
import Popper from "@mui/material/Popper";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import LogoutIcon from "@mui/icons-material/Logout";
import { useQueryClient } from "@tanstack/react-query";

interface GridElementProps {
  xs?: number;
  sm?: number;
  lg?: number;
  sx?: SxProps;
  justifyContent?: StackProps["justifyContent"];
}

const GridElement: FCWithChildren<GridElementProps> = ({
  children,
  xs,
  sm,
  lg,
  sx,
  justifyContent,
}) => (
  <Grid item xs={xs} sm={sm} lg={lg} sx={sx}>
    <Stack
      direction="row"
      justifyContent={justifyContent || "center"}
      alignItems="center"
    >
      {children}
    </Stack>
  </Grid>
);

const shortenName = (name: string) => {
  const [valid, first, last] = name.trim().match(/^(\S).*\s(\S)\S*$/i) ?? [];
  if (!valid) return name[0]?.toUpperCase() ?? "";
  const combined = first + last;
  return combined.toUpperCase();
};

const SwitchUserDialog = ({
  setDialogOpen,
  dialogOpen,
}: {
  dialogOpen: boolean;
  setDialogOpen: (value: boolean) => void;
}) => {
  const token = useStore(({ auth }) => auth.token);
  const setToken = useStore(({ auth }) => auth.setToken);
  const [loadingBcId, setLoadingBcId] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const { data, isLoading } = apiHooks.useQuery(
    "get",
    "/v1/identity/me",
    undefined,
    { enabled: !!token },
  );
  const { mutateAsync: changeUser } = apiHooks.useMutation(
    "post",
    "/v1/identity/switch",
  );
  const { t } = useTranslation();

  const handleSwitchUser = async (bcId: string) => {
    setLoadingBcId(bcId);
    try {
      const { data } = await changeUser({ body: { bcId } });
      queryClient.clear();
      setToken(data.token as string);
      setDialogOpen(false);
    } finally {
      setLoadingBcId(null);
    }
  };

  if (!token || !data) return null;

  const avatarSize = 56;

  const avatarPropsFromName = (
    name: string,
    extra?: Schemas["MeResponseBody"]["linked"][number],
  ) => {
    return {
      sx: {
        bgcolor: isLoading && extra && extra.bcId !== loadingBcId
          ? "action.disabled"
          : stringToColor(name),
        width: avatarSize,
        height: avatarSize,
      },
      children: extra && extra.bcId === loadingBcId
        ? <CircularProgress size="1.3em" sx={{ color: "background.paper" }} />
        : (
          shortenName(name)
        ),
    };
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      // maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          px: 2,
          mb: 0,
        },
      }}
    >
      <DialogTitle variant="h4" textAlign="center" mb={1}>
        {t("switch.title")}
      </DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <Stack direction="row" alignItems="center" gap={2} width="100%">
          <Avatar {...avatarPropsFromName(data.data.current.name)} />
          <Typography>{data.data.current.name}</Typography>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Stack gap={2}>
          {data.data.linked
            .filter((user) => user.bcId !== data.data.current.id)
            .map((user, i) => (
              <Button
                disabled={isLoading}
                key={i}
                onClick={() => handleSwitchUser(user.bcId)}
                sx={(t) => ({
                  fontWeight: t.typography.fontWeightRegular,
                  borderRadius: "100vmax 0 0 100vmax",
                  p: t.spacing(0, 2, 0, 0),
                })}
                endIcon={<MUIIcon name="swap_horiz" />}
              >
                <Stack direction="row" alignItems="center" gap={2} width="100%">
                  <Avatar {...avatarPropsFromName(user.name, user)} />
                  <Typography>{user.name}</Typography>
                </Stack>
              </Button>
            ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogOpen(false)}>Lukk</Button>
      </DialogActions>
    </Dialog>
  );
};

const ProfilePopper = () => {
  const { t } = useTranslation();
  const token = useStore(({ auth }) => auth.token);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { data } = apiHooks.useQuery("get", "/v1/identity/me", undefined, {
    enabled: !!token,
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const removeToken = useStore(({ auth }) => auth.removeToken);
  const queryClient = useQueryClient();

  const onLogout = () => {
    removeToken();
    queryClient.clear();
  };

  if (!data) return null;

  const canSwitchUser = data.data.linked.length >= 2;

  return (
    <>
      <IconButton onClick={handleClick}>
        <Avatar
          sx={(t) => ({
            bgcolor: t.palette.secondary.main,
            width: { xs: "2rem", sm: "2.3rem" },
            height: { xs: "2rem", sm: "2.3rem" },
          })}
        >
          <MUIIcon
            name="perm_identity"
            sx={{ fontSize: { xs: "1.2rem", sm: "1.4rem" } }}
          />
        </Avatar>
      </IconButton>
      <Popper open={open} anchorEl={anchorEl} placement="bottom-end" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={(t) => ({
                p: 0,
                backgroundColor: t.palette.background.default,
              })}
            >
              <List>
                {canSwitchUser && (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ py: 2, paddingRight: 4 }}
                        onClick={() => setDialogOpen(true)}
                      >
                        <ListItemIcon>
                          <SwapHorizIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("header.switch_entity")} />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </>
                )}
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ py: 2, paddingRight: 4 }}
                    onClick={onLogout}
                  >
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("header.logout")} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
      <SwitchUserDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
    </>
  );
};

const Header = () => {
  const { t } = useTranslation();
  const token = useStore(({ auth }) => auth.token);

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <StaticButton
        sx={{
          borderRadius: 0,
          width: 1,
          py: 1,
          justifyContent: "flex-start",
          display: { sm: "none" },
        }}
        arrow="before"
        href={t("general.website_url")}
      >
        {t("general.to_website_url_text")}
      </StaticButton>
      <Grid container p={2} alignItems="center" justifyContent="space-between">
        <GridElement sm={4} sx={{ display: { xs: "none", sm: "block" } }}>
          <StaticButton
            transparent
            arrow="before"
            href={t("general.website_url")}
          >
            {t("general.to_website_url_text")}
          </StaticButton>
        </GridElement>
        <GridElement
          xs={token ? 6 : 12}
          sm={4}
          justifyContent={{ xs: token ? "left" : "center", sm: "center" }}
        >
          <Logo height="50px" maxWidth="100%" href={PATH_ROOT} />
        </GridElement>
        <GridElement
          xs={6}
          sm={4}
          justifyContent={{ xs: "right", sm: "center" }}
        >
          {token && <ProfilePopper />}
        </GridElement>
      </Grid>
    </Box>
  );
};

export default Header;
