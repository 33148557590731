import { PATH as PATH_ROOT } from "../root";
import { useStore } from "../../store/store";
import { useLocation } from "react-router";
import { Box, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { apiHooks } from "../../lib/apis/norskGassnettApiHooks";
import AnonymousWrapper from "../../lib/components/atomics/AnonymousWrapper";
import CollapsableAlert from "../../lib/components/complex/CollapsableAlert";
import { useErrorMessage } from "../../lib/hooks/useErrorMessage";
import LoadingButton from "../../lib/components/atomics/LoadingButton";
import MUIIcon from "../../lib/components/atomics/MUIIcon";
import { EMAIL_REGEX, UNPARSED_PHONE_REGEX } from "../../lib/helpers/regex";
import { parsePhoneNumber } from "../../lib/helpers/utils";

export const PATH_SEGMENT = "logginn";
export const PATH = `/${PATH_SEGMENT}`;
export const getPath = () => PATH;

export const Login = () => {
  const { t } = useTranslation();
  const er = useErrorMessage();
  const navigate = useNavigate();

  const token = useStore(({ auth }) => auth.token);
  const setToken = useStore(({ auth }) => auth.setToken);
  const location = useLocation();
  const urlRedirect = (location.state as { from?: string })?.from || PATH_ROOT;
  const [searchParams] = useSearchParams();

  const searchParamValue = searchParams.get("value") ?? "";
  const searchParamCode = searchParams.get("code") ?? "";

  const startLoginMutation = apiHooks.useMutation(
    "post",
    "/v1/auth/start-login-flow",
  );
  const completeLoginMutation = apiHooks.useMutation(
    "post",
    "/v1/auth/complete-login-flow",
  );

  const parseIdentifierValue = (originalValue: string) => {
    const trimmedString = originalValue.trim();

    const isMobileNumber = UNPARSED_PHONE_REGEX.test(trimmedString);
    const isEmail = EMAIL_REGEX.test(trimmedString);

    if (isMobileNumber) {
      const parsedValue = parsePhoneNumber(trimmedString);
      if (parsedValue) {
        return { type: "number" as const, originalValue, parsedValue };
      }
    }
    if (isEmail) {
      return {
        type: "email" as const,
        originalValue,
        parsedValue: trimmedString,
      };
    }
    return {
      type: "unknown" as const,
      originalValue,
      parsedValue: trimmedString,
    };
  };

  const [customIdentifierError, setCustomIdentifierError] = useState("");
  const [userIdentifier, setUserIdentifier] = useState(searchParamValue);
  const [parsedIdentifier, setParsedIdentifier] = useState<string>(
    searchParamValue,
  );
  const handleUpdateCredentials = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserIdentifier(e.target.value);
    setCustomIdentifierError("");
  };

  const [hideCodeError, setHideCodeError] = useState(false);
  const [userCode, setUserCode] = useState(searchParamCode);
  const handleUpdateCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!/^\d{0,6}$/.test(value)) return;
    setUserCode(value);
  };

  useEffect(() => {
    if (token) {
      navigate(urlRedirect, { replace: true });
    }
  }, [token, navigate, urlRedirect]);

  const onSubmitCredentials = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { type, parsedValue } = parseIdentifierValue(userIdentifier);
      if (type === "unknown") {
        setCustomIdentifierError(t("login.invalid_identifier"));
        return;
      }
      await startLoginMutation.mutateAsync({
        body: {
          value: parsedValue,
          type: type,
        },
      });
      setUserCode("");
      setHideCodeError(true);
      setParsedIdentifier(parsedValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startLoginMutation.mutateAsync, t, userIdentifier],
  );

  const login = useCallback(
    async (credentials: string, code: string) => {
      const body = {
        value: credentials,
        code: code.toUpperCase(),
      };
      const res = await completeLoginMutation.mutateAsync({ body });
      if (res.data?.token) {
        setToken(res.data.token);
      }
    },
    [setToken, completeLoginMutation.mutateAsync],
  );

  const onSubmitCode = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      login(parsedIdentifier, userCode);
      setHideCodeError(false);
    },
    [login, parsedIdentifier, userCode],
  );

  const resetFn = () => setParsedIdentifier("");

  return (
    <AnonymousWrapper backFunction={!!parsedIdentifier && resetFn}>
      <Typography component="h1" variant="h5">
        {t("login.title")}
      </Typography>
      <Box
        component="form"
        onSubmit={!parsedIdentifier ? onSubmitCredentials : onSubmitCode}
        noValidate
        sx={{ mt: 1, width: 1 }}
      >
        {!parsedIdentifier
          ? (
            <>
              <TextField
                key="identifier"
                margin="normal"
                required
                fullWidth
                id="identifier"
                label={t("login.input_identifier")}
                name="identifier"
                autoComplete="username"
                autoFocus
                disabled={startLoginMutation.isPending}
                value={userIdentifier}
                onChange={handleUpdateCredentials}
              />
              <CollapsableAlert
                alert={customIdentifierError ||
                  er("login", startLoginMutation.error ?? undefined)}
              />
              <LoadingButton
                type="submit"
                fullWidth
                sx={{ mt: 2, mb: 1 }}
                disabled={!!customIdentifierError || !userIdentifier}
                loading={startLoginMutation.isPending}
                endIcon={<MUIIcon name="arrow_forward" />}
              >
                {t("login.send_code_btn")}
              </LoadingButton>
            </>
          )
          : (
            <>
              <TextField
                key="code"
                margin="normal"
                required
                fullWidth
                id="code"
                label={t("login.label_code")}
                name="code"
                autoComplete="one-time-code"
                autoFocus
                disabled={completeLoginMutation.isPending}
                value={userCode}
                onChange={handleUpdateCode}
              />
              <CollapsableAlert
                alert={!hideCodeError &&
                  er("login", completeLoginMutation.error)}
              />
              <LoadingButton
                type="submit"
                fullWidth
                sx={{ mt: 2, mb: 1 }}
                disabled={!parsedIdentifier || userCode.length !== 6}
                loading={completeLoginMutation.isPending}
                endIcon={<MUIIcon name="arrow_forward" />}
              >
                {t("login.login_btn")}
              </LoadingButton>
            </>
          )}
      </Box>
    </AnonymousWrapper>
  );
};

export default Login;
