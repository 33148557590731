import { createTheme, LinkProps, ThemeOptions } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/nb";
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { nbNO as coreNbNO } from "@mui/material/locale";
import { nbNO as pickersNbNO } from "@mui/x-date-pickers";
import { DeepPartial } from "../types";

declare module "@mui/material" {
  interface Theme {
    custom: {
      fieldSpacing: number;
      usageBar: string;
    };
  }
  type ThemeOptions = DeepPartial<Theme>;
}

export interface CustomThemeOptions extends ThemeOptions {
  custom: {
    usageBar: string;
  };
}
const themeOptions: CustomThemeOptions = {
  custom: {
    usageBar: "#2C7F97",
  },
  palette: {
    mode: "light",
    primary: {
      main: "#00485D",
      light: "#B8D3DD",
      dark: "#00485D",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#42C2B4",
      light: "#92dcd4",
      dark: "#33a094",
      contrastText: "#003b4a",
    },
    error: {
      main: "#d0021b",
      light: "#e21a3b",
      dark: "#960019",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#F28749",
      light: "#ff8d40",
      dark: "#d16115",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#2cb158",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#5c99af",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#F7F7F7",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#00485D",
    },
    divider: "#e6f0f3",
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: 'Poppins, "Helvetica", "Arial", sans-serif',
    fontSize: 15,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 1000,
    h1: {
      fontWeight: 600,
      fontSize: "2.4rem",
    },
    h2: {
      fontSize: "1.8rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1.2rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "1rem",
      fontWeight: 600,
    },
    h6: {
      fontSize: "0.9rem",
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    body1: {
      fontSize: "1rem",
    },
    button: {
      fontSize: "1rem",
      fontWeight: 600,
    },
    caption: {
      fontSize: "1rem",
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          bgcolor: "#38CC9D",
          color: "primary.main",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 20,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiIcon: {
      defaultProps: {
        baseClassName: "material-symbols-outlined",
      },
    },
  },
};

type MUILinkProps = Omit<RouterLinkProps, "to"> & {
  href: RouterLinkProps["to"];
};
const LinkBehavior = React.forwardRef<HTMLAnchorElement, MUILinkProps>(
  (props, ref) => {
    const { href, ...other } = props;
    if (/^(tel|mailto|http)/.test(href.toString())) {
      return <a ref={ref} href={href.toString()} {...other} />;
    }
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />;
  },
);

let theme = createTheme(themeOptions, coreNbNO, pickersNbNO);
theme = createTheme(theme, {
  palette: {
    text: {
      primary: theme.palette.primary.dark,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
        underline: "hover",
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1, 3),
          borderRadius: theme.spacing(1),
        },
      },
    },
  },
});

dayjs.locale("nb");
export { theme };
