import { Link, LinkProps, Typography, TypographyProps } from "@mui/material";
import { Fragment } from "react";

export interface TextParserProps extends TypographyProps {
  content?: string;
  children?: string;
  library: {
    [href: string]: {
      text: string;
      link: string;
    };
  };
  linkProps?: LinkProps;
}

/**
 * const lib = {
 *  toLoginLink: {
 *    text: 'here',
 *    link: '/login'
 *  },
 *  toRegisterLink: {
 *    text: 'HERE',
 *    link: '/register'
 *  }
 * }
 * <TextParser library={lib}>Hello click {{toLoginLink}} to go to login and {{toRegisterLink}} to go to register</TextParser>
 *
 * Gives
 *
 * Hello click here to go to logon and HERE to go to register
 *              ^                       ^
 *             link                    link
 *
 * ../complex/TranslatedLink gives a shorthand which is compatible with t18
 */
const TextParser = ({
  content,
  children = content,
  library,
  linkProps = {},
  color,
  ...props
}: TextParserProps) => {
  if (!children) return <Typography {...props} />;
  const strings = children.split(/{{\w+}}/);
  const markers = children.matchAll(/{{(\w+)}}/g);
  const c = [];

  for (let i = 0; i < strings.length; i++) {
    const string = strings[i];
    const marker = markers?.next().value?.[1];
    c.push(<Fragment key={i * 2}>{string}</Fragment>);
    if (!marker) continue;
    const data = library[marker];
    if (!data) continue;
    c.push(
      <Link color={color} {...linkProps} key={i * 2 + 1} href={data.link}>
        {data.text}
      </Link>,
    );
  }
  return (
    <Typography color={color} {...props}>
      {c}
    </Typography>
  );
};

export default TextParser;
