import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import SubpageWrapper from "../../lib/components/atomics/SubpageWrapper";
import { apiHooks, Schemas } from "../../lib/apis/norskGassnettApiHooks";
import { useIdentifiedContext } from "../../lib/contexts/IdentifiedContext";
import { Card, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import LoadingButton from "../../lib/components/atomics/LoadingButton";
import MUIIcon from "../../lib/components/atomics/MUIIcon";
import { downloadFile } from "../../lib/helpers/utils";
import { PATH as PATH_ROOT } from "../root";
import Loader from "../../lib/components/atomics/Loader";
import { useCumulativeQuery } from "../../lib/hooks/useCumulativeQuery";

export const PATH_SEGMENT = "service-rapporter";
export const PATH = `/${PATH_SEGMENT}`;
export const getPath = () => PATH;

interface ServiceReportProps {
  report: Schemas["ServiceReport"];
}

const ServiceReport: React.FC<ServiceReportProps> = ({ report }) => {
  const { t } = useTranslation();
  const { mutateAsync, error, isPending } = apiHooks.useMutation(
    "get",
    "/v1/service-report/{id}",
  );

  const handleGetPdf = useCallback(async () => {
    const data = await mutateAsync({
      params: {
        path: {
          id: report.SystemId,
        },
      },
    });
    let pdfName = t("service_reports.standard_report_name");

    if (report.dato) {
      const date = dayjs(report.dato).format("YYYY-MM-DD");
      pdfName = t("service_reports.report_name").replace("{{date}}", date);
    }
    await downloadFile({
      name: pdfName,
      data: data.data.serviceReport.pdfContent,
    });
  }, [report, mutateAsync, t]);

  return (
    <Card sx={{ borderRadius: 0, p: 0, width: "100%" }}>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        gap={3}
        sx={{
          py: 2,
          px: 3,
        }}
      >
        <Stack>
          <Typography variant="h4" fontSize="1.1rem">
            {t("service_reports.card_title")}
          </Typography>
          <Typography variant="subtitle2" fontSize="1rem">
            {dayjs(report.dato).format("DD.MM.YYYY")}
          </Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <LoadingButton
            square
            variant="outlined"
            startIcon={
              <MUIIcon name={error ? "file_download_off" : "download"} />
            }
            loading={isPending}
            disabled={!!error}
            title={t("service_reports.download") ?? ""}
            onClick={!error && handleGetPdf}
          >
            {t("service_reports.download")}
          </LoadingButton>
        </Stack>
      </Stack>
    </Card>
  );
};

const ServiceReports: React.FC = () => {
  const { t } = useTranslation();
  const { data: reports, reachedEnd, fetchMore, isPending } =
    useCumulativeQuery(
      "/v1/service-report",
      5,
      (d) => d.serviceReports,
    );

  return (
    <SubpageWrapper title={t("service_reports.title")} backRef={PATH_ROOT}>
      <Stack width="100%" gap={2} sx={{ pt: 3, pb: 7 }}>
        {reports?.length === 0 && (
          <Typography
            variant="subtitle1"
            textAlign="center"
            sx={{ opacity: 0.4 }}
          >
            {t("service_reports.no_reports")}
          </Typography>
        )}
        {reports?.map((report) => (
          <ServiceReport key={report.SystemId} report={report} />
        ))}
        {!reports && isPending && <Loader />}
      </Stack>
      <LoadingButton
        variant="outlined"
        disabled={reachedEnd}
        loading={isPending}
        endIcon={
          <MUIIcon name={reachedEnd ? "event_available" : "event_repeat"} />
        }
        onClick={fetchMore}
      >
        {t(`service_reports.${reachedEnd ? "no_more_reports" : "show_more"}`)}
      </LoadingButton>
    </SubpageWrapper>
  );
};

export default ServiceReports;
