import React, { useMemo, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  getBorderRadius,
  getMonthName,
  numberToPrice,
} from "../../lib/helpers/utils";
import SubpageWrapper from "../../lib/components/atomics/SubpageWrapper";
import { apiHooks, Schemas } from "../../lib/apis/norskGassnettApiHooks";
import GasBarGraph from "../../lib/components/complex/GasBarGraph";
import Loader from "../../lib/components/atomics/Loader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import OptionPicker from "../../lib/components/complex/OptionPicker";
import { useIsIdentityLng } from "../../lib/hooks/useIsIdentityLng";

export const PATH_SEGMENT = "forbruk";
export const PATH = `/${PATH_SEGMENT}`;
export const getPath = () => PATH;

const getYearStart = (date: Date | string | number) => {
  if (typeof date === "object") return `${date.getFullYear()}-01-01`;
  return `${date}-01-01`;
};

const getYearEnd = (date: Date | string | number) => {
  if (typeof date === "object") return `${date.getFullYear()}-12-31`;
  return `${date}-12-31`;
};

const getMonthEnd = (date: Date): string => {
  const t = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return t.toISOString().split("T")[0];
};

function getYearsInRange(startYear: string, endYear: string) {
  const start = parseInt(startYear, 10);
  const end = parseInt(endYear, 10);
  const years: string[] = [];

  for (let year = start; year <= end; year++) {
    years.push(year.toString());
  }

  return years;
}

const getYearFromDateString = (date: string) => date.split("-")[0];

/**
 * Calculates the price per kilowatt from the price per kg
 */
const priceKgToKWt = (price = 0) => numberToPrice(price / 12.86);

/**
 * Calculates the price per kilowatt from the price per sm3
 */
const priceSm3ToKWt = (price = 0) => numberToPrice(price / 10.2);

const BarGraph: React.FC = () => {
  const { t } = useTranslation();

  const [barVariant, setBarVariant] = useState<"tertiary" | "monthly">(
    "tertiary",
  );

  const res = apiHooks.useQuery("get", "/v1/meter-reading/consumption", {
    params: {
      query: {
        dateFrom: getYearStart(new Date().getFullYear() - 3),
        dateTo: getYearEnd(new Date()),
      },
    },
  });

  return (
    <Paper sx={{ py: 2, px: 0, width: "100%", borderRadius: getBorderRadius }}>
      <Box sx={{ mx: 4 }}>
        <Stack direction="row" justifyContent="center" gap={2} sx={{ mb: 2 }}>
          <Chip
            label={t("usage.tertiary")}
            variant={barVariant === "tertiary" ? "filled" : "outlined"}
            color="primary"
            onClick={() =>
              setBarVariant("tertiary")}
          />
          <Chip
            label={t("usage.month")}
            variant={barVariant === "monthly" ? "filled" : "outlined"}
            color="primary"
            onClick={() => setBarVariant("monthly")}
          />
        </Stack>
      </Box>
      <Divider />
      <Box sx={{ mx: 3 }}>
        {res.data?.data
          ? (
            <GasBarGraph
              data={res.data.data.consumptions}
              grouping={barVariant}
            />
          )
          : (
            res.isLoading && <Loader />
          )}
      </Box>
    </Paper>
  );
};

const GasPriceSummary = ({ gasPrice }: { gasPrice: Schemas["GasPrice"] }) => {
  const { t } = useTranslation();

  const isLng = useIsIdentityLng();

  return (
    <List>
      <ListItem>
        <Stack px={2} flexGrow={1}>
          <Stack direction="row">
            <Typography sx={{ width: "66%", flexGrow: 1 }}>
              {t(
                isLng
                  ? "usage.gas_price_summary_base_price_sm3_txt"
                  : "usage.gas_price_summary_base_price_kg_txt",
              )}
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {numberToPrice(gasPrice.PrisPerKiloEksCO2)}
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography sx={{ width: "66%", flexGrow: 1 }}>
              {t("usage.gas_price_summary_co2_txt")}
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {numberToPrice(gasPrice.CO2avgift)}
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography sx={{ width: "66%", flexGrow: 1 }}>
              {t("usage.gas_price_summary_mva_txt")}
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {numberToPrice(gasPrice.VATAmount)}
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography sx={{ width: "66%", flexGrow: 1 }} fontWeight="bold">
              {t("usage.gas_price_summary_total_txt")}
            </Typography>
            <Typography sx={{ color: "text.secondary" }} fontWeight="bold">
              {numberToPrice(gasPrice.TotalInclVAT)}
            </Typography>
          </Stack>
        </Stack>
      </ListItem>
    </List>
  );
};

const GasPriceCurrent: React.FC = () => {
  const { t } = useTranslation();

  const isLng = useIsIdentityLng();

  const gasPrices = apiHooks.useQuery("get", "/v1/gas-price", {
    params: {
      query: {
        dateFrom: getYearStart(new Date().getFullYear() - 2),
        dateTo: getYearEnd(new Date()),
      },
    },
  });

  if (gasPrices.isLoading) return <Loader />;

  if (!gasPrices.data?.data.gasPrices?.length) {
    return (
      <Typography variant="h3">{t("usage.gas_prices_not_found")}</Typography>
    );
  }

  const currentPrice = gasPrices.data?.data
    ?.gasPrices[gasPrices.data?.data.gasPrices.length - 1];

  if (!currentPrice) {
    return (
      <Typography variant="h3">
        {t("usage.current_gas_price_not_found")}
      </Typography>
    );
  }

  return (
    <Accordion
      sx={{
        width: "100%",
        "& .MuiAccordion-root": {
          padding: "0",
        },
        padding: "0",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={(theme) => ({ backgroundColor: theme.palette.primary.light })}
      >
        <Stack
          direction="row"
          sx={(theme) => ({ backgroundColor: theme.palette.primary.light })}
          justifyContent="space-between"
          paddingX={2}
          paddingY={2}
          width="100%"
        >
          <Typography variant="h3">
            {t(isLng ? "usage.gas_price_sm3" : "usage.gas_price_kg")}
          </Typography>
          <Typography variant="h3">
            {numberToPrice(currentPrice.TotalInclVAT)}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{ paddingX: 0, paddingTop: "1px", paddingBottom: 0 }}
      >
        <Stack
          direction="row"
          sx={(theme) => ({ backgroundColor: theme.palette.primary.light })}
          justifyContent="space-between"
          paddingX={4}
          paddingY={2}
        >
          <Typography>
            {t("usage.gas_price_summary_kw_calculation_txt")}
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            {isLng
              ? priceSm3ToKWt(currentPrice.TotalInclVAT)
              : priceKgToKWt(currentPrice.TotalInclVAT)}
          </Typography>
        </Stack>
        <GasPriceSummary gasPrice={currentPrice} />
      </AccordionDetails>
    </Accordion>
  );
};

function GasPriceHistoryTableRow({ row }: { row: Schemas["GasPrice"] }) {
  const [open, setOpen] = React.useState(false);
  const { i18n } = useTranslation();
  const isLng = useIsIdentityLng();

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
      >
        <TableCell component="th" scope="row">
          <Typography fontWeight="bold">
            {getMonthName(row.Dato, i18n.language)}
          </Typography>
        </TableCell>
        <TableCell align="right">
          {isLng
            ? priceSm3ToKWt(row.TotalInclVAT)
            : priceKgToKWt(row.TotalInclVAT)}
        </TableCell>
        <TableCell align="right">
          <Typography fontWeight="bold">
            {numberToPrice(row.TotalInclVAT)}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.default,
            paddingRight: { xs: 6, md: 8 },
          })}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <GasPriceSummary gasPrice={row} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const GasPriceHistory: React.FC = () => {
  const { t } = useTranslation();

  const isLng = useIsIdentityLng();

  const [dateFrom] = useState(getYearStart(new Date().getFullYear() - 2));
  const [dateTo] = useState(getMonthEnd(new Date()));

  const gasPrices = apiHooks.useQuery("get", "/v1/gas-price", {
    params: {
      query: {
        dateFrom,
        dateTo,
      },
    },
  });

  const parsedGasPrices = useMemo<Record<string, Schemas["GasPrice"][]>>(() => {
    if (!gasPrices.data?.data.gasPrices?.length) return [];

    // Group the data by month
    const groupedDataByYear = gasPrices.data.data.gasPrices.reduce(
      (groups, entry) => {
        const year = entry.Dato.slice(0, 4); // Extract the year
        const month = entry.Dato.slice(0, 7); // Extract the year and month
        if (!groups[year]) {
          groups[year] = {};
        }
        if (!groups[year][month]) {
          groups[year][month] = [];
        }
        groups[year][month].push(entry);
        return groups;
      },
      {} as Record<string, Record<string, Schemas["GasPrice"][]>>,
    );

    const filteredDataByYear = Object.entries(groupedDataByYear).reduce(
      (acc, [year, months]) => {
        const filteredMonths = Object.values(months).map(
          (month) => month[month.length - 1],
        );

        acc[year] = filteredMonths.sort((a, b) => (a.Dato < b.Dato ? 1 : -1));
        return acc;
      },
      {},
    );

    return filteredDataByYear;
  }, [gasPrices.data]);

  const [selectedYear, setSelectedYear] = useState<string>(
    getYearFromDateString(dateTo),
  );
  const yearsArray = useMemo(() => {
    return getYearsInRange(dateFrom, dateTo);
  }, [dateFrom, dateTo]);

  if (gasPrices.isLoading) return <Loader />;

  return (
    <>
      <Paper sx={{ p: 0, width: "100%", borderRadius: getBorderRadius }}>
        <Box sx={{ p: 3 }}>
          <TableContainer>
            <Stack direction="row" justifyContent="space-between" paddingX={2}>
              <Typography variant="h4">{t("usage.history")}</Typography>
              <OptionPicker
                options={yearsArray.map((v) => ({ label: v, value: v }))}
                value={selectedYear}
                onChange={(v) => setSelectedYear(v ?? selectedYear)}
              />
            </Stack>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: "60%" }}>
                    {t("usage.month")}
                  </TableCell>
                  <TableCell align="right">{t("usage.price_kwt")}</TableCell>
                  <TableCell align="right">
                    {t(isLng ? "usage.price_sm3" : "usage.price_kg")}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {parsedGasPrices[selectedYear]?.map((row) => (
                  <GasPriceHistoryTableRow key={row.id} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </>
  );
};

const Usage: React.FC = () => {
  const { t } = useTranslation();

  const isLng = useIsIdentityLng();

  return (
    <SubpageWrapper title={t("usage.header")} StackProps={{ gap: 3 }}>
      <BarGraph />
      <GasPriceCurrent />
      <Typography variant="h5" marginY={2}>
        {t(
          isLng
            ? "usage.gas_price_calculation_sm3"
            : "usage.gas_price_calculation_kg",
        )}
      </Typography>
      <GasPriceHistory />
    </SubpageWrapper>
  );
};

export default Usage;
